import contentAPIRequest from '../utility/content-api-request';

export default {
  async getCategories(options = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await contentAPIRequest('/categories', {
          method: 'GET',
          query: {
            per_page: 100,
          },
        });

        // transform response
        const data = response?.data.map((category) => {
          return {
            id: category.id,
            type: category?.acf?.category_type ?? 'category',
            count: category.count,
            path: category.slug,
            name: category.name,
            showInMenu: category?.acf?.show_in_menu ?? false,
          };
        })
          .filter((category) => {
            // disable filtering when no filters are specified
            if (options?.type === undefined) {
              return true;
            }

            // filter by type
            if (options?.type !== undefined) {
              if (category?.type === options.type) {
                return true;
              }
            }
          });

        // resolve
        resolve({
          data,
          metadata: null,
        });

      } catch (error) {
        reject(error);
      }
    });
  },
  async getCategory(options = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const additionalQueries = {};

        if (options?.path !== undefined) {
          additionalQueries.slug = options.path;
        }

        const response = await contentAPIRequest('/categories', {
          method: 'GET',
          query: {
            per_page: 1,
            ...additionalQueries,
          },
          transformQuery: 'kebab-case',
        });

        let data = null;

        if (response?.data.length === 0) {
          data = null;
        } else {
          // transform response
          const category = response.data[0];

          data = {
            id: category.id,
            type: category?.acf?.category_type ?? 'category',
            count: category.count,
            path: category.slug,
            name: category.name,
            showInMenu: category?.acf?.show_in_menu ?? false,
          };
        }

        // resolve
        resolve({
          data,
          metadata: null,
        });

      } catch (error) {
        reject(error);
      }
    });
  },
};
