/*
  eslint no-shadow: ["error", { "allow": ["state"] }]
*/

const state = {
  statusBar: {
    content: '',
    url: null,
    isVisible: false,
  },
};

const getters = {
  statusBar: (state) => state.statusBar,
};

const actions = {
  async fetchStatusBar({ commit }) {
    const response = await fetch('https://redaktion.lokaldirekt.de/wp-json/wp/v2/status_bar?_embed&per_page=1');

    const statusBar = await response.json();

    const statusBarConfig = {
      content: '',
      url: null,
      isVisible: false,
    };

    if (statusBar.length) {
      statusBarConfig.isVisible = true;

      // eslint-disable-next-line dot-notation
      if (statusBar[0].acf['status_bar_content']) {
        // eslint-disable-next-line dot-notation
        statusBarConfig.content = statusBar[0].acf['status_bar_content'];
      }

      // eslint-disable-next-line dot-notation
      if (statusBar[0].acf['link']) {
        // eslint-disable-next-line dot-notation
        statusBarConfig.url = statusBar[0].acf['link'];
      }
    }

    //         image = post.acf['preview-image'];

    commit('setStatusBar', statusBarConfig);
  },
};

const mutations = {
  setStatusBar: (state, statusBar) => {
    state.statusBar = statusBar;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
