import { createRouter, createWebHistory } from '@ionic/vue-router';

export default createRouter({
  history: createWebHistory('/'),
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'Start',
      // component: Start,
      component: () => import('../views/Start/index.vue'),
    },
    {
      path: '/rubriken',
      name: 'Rubrics',
      component: () => import('../views/Rubrics/index.vue'),
    },
    {
      path: '/orte',
      name: 'Places',
      redirect: '/rubriken',
    },
    {
      path: '/ratgeber',
      name: 'Advisor',
      // component: Advisor,
      component: () => import('../views/Advisor/index.vue'),
    },
    {
      path: '/stellenangebote',
      name: 'Jobs',
      component: () => import('../views/Jobs/index.vue'),
    },
    {
      path: '/service',
      name: 'Service',
      component: () => import('../views/Service/index.vue'),
    },
    {
      path: '/impressum',
      name: 'Imprint',
      component: () => import('../views/static/Imprint/index.vue'),
    },
    {
      path: '/datenschutz',
      name: 'Privacy',
      component: () => import('../views/static/Privacy/index.vue'),
    },
    {
      path: '/kategorie/:pathName',
      name: 'CategoryDetail',
      component: () => import('../views/CategoryDetail/index.vue'),
    },
    {
      path: '/ort/:pathName',
      name: 'PlaceDetail',
      component: () => import('../views/PlaceDetail/index.vue'),
    },
    {
      path: '/notdienste',
      name: 'EmergencyServices',
      component: () => import('../views/EmergencyServices/index.vue'),
    },
    {
      path: '/kinoprogramm',
      name: 'CinemaProgram',
      component: () => import('../views/CinemaProgram/index.vue'),
    },
    {
      path: '/fahrplaene',
      name: 'BusSchedules',
      component: () => import('../views/BusSchedules/index.vue'),
    },
    {
      path: '/lesezeichen',
      name: 'ReadingList',
      component: () => import('../views/ReadingList/index.vue'),
    },
    {
      path: '/events',
      name: 'Events',
      component: () => import('../views/Events/index.vue'),
    },
    {
      path: '/news/:pathName',
      name: 'NewsDetail',
      // component: NewsDetail,
      component: () => import('../views/NewsDetail/index.vue'),
    },
    {
      path: '/job/:pathName',
      name: 'JobDetail',
      component: () => import('../views/JobDetail/index.vue'),
    },
    {
      path: '/ad',
      name: 'DefaultAd',
      component: () => import('../views/ads/DefaultAd/index.vue'),
    },
    {
      path: '/article-ad',
      name: 'ArticleAd',
      component: () => import('../views/ads/ArticleAd/index.vue'),
    },
    {
      path: '/anzeigen',
      name: 'CurrentlyShownAds',
      component: () => import('../views/CurrentlyShownAds/index.vue'),
    },
    {
      path: '/wetter',
      name: 'Weather',
      component: () => import('../views/Weather/index.vue'),
    },
    {
      path: '/todesanzeigen',
      name: 'Obituaries',
      component: () => import('../views/Obituaries/index.vue'),
    },
    {
      path: '/angebote',
      name: 'Brochures',
      component: () => import('../views/Brochures/index.vue'),
    },
    {
      path: '/a45',
      name: 'A45',
      component: () => import('../views/A45/index.vue'),
    },
    {
      path: '/kontakt',
      name: 'Contact',
      component: () => import('../views/static/Contact/index.vue'),
    },
    {
      path: '/ueber-uns',
      name: 'AboutUs',
      component: () => import('../views/static/AboutUs/index.vue'),
    },
    {
      path: '/team',
      name: 'TheTeam',
      component: () => import('../views/static/TheTeam/index.vue'),
    },
    {
      path: '/werbung-schalten',
      name: 'Advertisers',
      component: () => import('../views/static/Advertisers/index.vue'),
    },
    {
      path: '/suchen',
      name: 'Search',
      component: () => import('../views/Search/index.vue'),
    },
    {
      path: '/onboarding',
      name: 'Onboarding',
      component: () => import('../views/Onboarding/index.vue'),
    },
    // {
    //   path: '/auth',
    //   name: 'Auth',
    //   component: () => import('../views/Auth/index.vue'),
    // },
    {
      path: '/auth',
      name: 'Auth',
      redirect: '/partner',
    },
    {
      path: '/partner',
      name: 'PartnerDashboard',
      component: () => import('../views/PartnerDashboard/index.vue'),
    },
    {
      path: '/ads/:adId',
      name: 'AdDetail',
      component: () => import('../views/AdDetail/index.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'Error404',
      component: () => import('../views/errors/Error404/index.vue'),
    },
  ],
});
