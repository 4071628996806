import Vuex from 'vuex';

import environment from './modules/environment';
import posts from './modules/posts';
import events from './modules/events';
import categories from './modules/categories';
import places from './modules/places';
import industries from './modules/industries';
import guides from './modules/guides';
import departments from './modules/departments';
import ads from './modules/ads';
import jobs from './modules/jobs';
import weather from './modules/weather';
import obituaries from './modules/obituaries';
import brochures from './modules/brochures';
import congestions from './modules/congestions';
import myApp from './modules/my-app';
import statusBar from './modules/status-bar';
import pushNotifications from './modules/push-notifications';

export default Vuex.createStore({
  modules: {
    environment,
    posts,
    categories,
    places,
    industries,
    guides,
    departments,
    events,
    ads,
    jobs,
    weather,
    obituaries,
    brochures,
    congestions,
    myApp,
    statusBar,
    pushNotifications,
  },
});
