export default {
  init(element) {
    function initObserver() {
      const ionContentElements = document.querySelectorAll('ion-content');
      const currentIonContentElement = ionContentElements[ionContentElements.length - 1];

      const observer = new IntersectionObserver(onIntersection, {
        root: null,
        threshold: 0,
        rootMargin: '200px 0px 200px 0px',
      });

      observer.observe(element);
    }

    function loadImage() {
      let imageElement = null;

      if (element.nodeName === 'IMG') {
        // image element
        imageElement = element;
      } else {
        // child element
        imageElement = Array.from(element.children).find((element) => {
          return element.nodeName === 'IMG';
        });
      }

      if (imageElement) {
        console.log('Load Image');

        imageElement.addEventListener('load', () => {
          setTimeout(() => element.classList.add('loaded'), 120);
        });

        imageElement.src = imageElement.dataset.src;
      }
    }

    function onIntersection(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadImage();
          observer.unobserve(element);
        }
      });
    }

    if (window['IntersectionObserver']) {
      initObserver();
    } else {
      loadImage();
    }
  },
};
