import contentAPIRequest from '../utility/content-api-request';

export default {
  async getPlaces(options = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await contentAPIRequest('/places', {
          method: 'GET',
          query: {
            per_page: 100,
          },
        });

        // transform response
        const data = response?.data.map((place) => {
          return {
            id: place.id,
            count: place.count,
            path: place.slug,
            name: place.name,
            showInMenu: place?.acf?.show_in_menu ?? false,
            allowWeatherForecast: place?.acf?.allow_weather_forecast ?? false,
            lat: place?.acf?.lat ?? '',
            lon: place?.acf?.lon ?? '',
          };
        });

        // resolve
        resolve({
          data,
          metadata: null,
        });

      } catch (error) {
        reject(error);
      }
    });
  },
  async getPlace(options = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const additionalQueries = {};

        if (options?.path !== undefined) {
          additionalQueries.slug = options.path;
        }

        const response = await contentAPIRequest('/places', {
          method: 'GET',
          query: {
            per_page: 1,
            ...additionalQueries,
          },
          transformQuery: 'kebab-case',
        });

        let data = null;

        if (response?.data.length === 0) {
          data = null;
        } else {
          // transform response
          const place = response.data[0];

          data = {
            id: place.id,
            count: place.count,
            path: place.slug,
            name: place.name,
            showInMenu: place?.acf?.show_in_menu ?? false,
            allowWeatherForecast: place?.acf?.allow_weather_forecast ?? false,
            lat: place?.acf?.lat ?? '',
            lon: place?.acf?.lon ?? '',
          };
        }

        // resolve
        resolve({
          data,
          metadata: null,
        });

      } catch (error) {
        reject(error);
      }
    });
  },
};
