/*
  eslint no-shadow: ["error", { "allow": ["state"] }]
*/

import { Preferences } from '@capacitor/preferences';

const state = {
  selectedWeatherForecast: {
    current: {
      temp: 0,
      description: 'Sonne',
      id: 0,
    },
    daily: [],
  },
  selectedPlace: {
    name: 'Halver',
    lat: '51.18612',
    lon: '7.49817',
    active: true,
  },
  privacy: {
    allowThirdPartyConnections: false,
  },
};

const getters = {
  selectedWeatherForecast: (state) => state.selectedWeatherForecast,
  weatherPrivacy: (state) => state.privacy,
  selectedPlace: (state) => state.selectedPlace,
};

const actions = {
  async fetchWeatherPrivacyConsent({ commit }) {
    let { value } = await Preferences.get({
      key: 'weatherallowthirdpartyconnections',
    });

    // eslint-disable-next-line no-unneeded-ternary
    value = value === 'yes' ? true : false; // make null value a Boolean value

    commit('setPrivacyConsent', value);
  },
  async setWeatherPrivacyConsent({ commit }, value) {
    let consent = 'no';

    if (value === 'true' || value === true) {
      consent = 'yes';
    } else {
      consent = null;
    }

    await Preferences.set({
      key: 'weatherallowthirdpartyconnections',
      value: consent,
    });

    commit('setPrivacyConsent', consent);
  },
  async fetchSelectedPlace({ commit }) {
    const { value } = await Preferences.get({
      key: 'weatherselectedplace',
    });

    // when selected place is null, set it to the default place
    const place = value !== null ? JSON.parse(value) : {
      name: 'Halver',
      lat: '51.18612',
      lon: '7.49817',
      active: true,
    };

    commit('setSelectedPlace', place);
  },
  async setSelectedPlace({ commit }, value) {
    // eslint-disable-next-line no-param-reassign
    value = JSON.parse(JSON.stringify(value));

    const place = value !== null ? value : {
      name: 'Halver',
      lat: '51.18612',
      lon: '7.49817',
      active: true,
    };

    const placeStringified = JSON.stringify(place);

    await Preferences.set({
      key: 'weatherselectedplace',
      value: placeStringified,
    });

    commit('setSelectedPlace', place);
  },
  // eslint-disable-next-line no-shadow
  async fetchWeatherForecast({ commit, getters }, { lat, lon }) {
    if (!getters.weatherPrivacy.allowThirdPartyConnections) {
      return;
    }

    console.log('Fetch Weather Data', lat, lon);

    const response = await fetch(`https://api.openweathermap.org/data/2.5/onecall?lat=${lat}&lon=${lon}&units=metric&lang=de&appid=374e2b77e9883e8d26b9a76cd15de912`);

    const weatherData = await response.json();

    const forecastData = {
      current: {
        description: 'Sonne',
        id: 0,
        temp: Math.round(weatherData.current.temp),
      },
      daily: [],
    };

    if (weatherData.current.weather[0]) {
      forecastData.current.description = weatherData.current.weather[0].description;
      forecastData.current.id = weatherData.current.weather[0].id;
    }

    const daily = [];
    if (weatherData.daily) {
      const daysOfTheWeek = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
      let selectedDayOfTheWeek = new Date().getDay() > 6 ? 0 : new Date().getDay();
      weatherData.daily.forEach((forecast) => {
        daily.push({
          timestamp: new Date(forecast.dt),
          description: forecast.weather[0].description,
          id: forecast.weather[0].id,
          maxTemp: Math.round(forecast.temp.max),
          minTemp: Math.round(forecast.temp.min),
          dayOfTheWeek: daysOfTheWeek[selectedDayOfTheWeek],
        });

        selectedDayOfTheWeek = selectedDayOfTheWeek + 1 > 6 ? 0 : selectedDayOfTheWeek + 1;
      });
    }

    forecastData.daily = daily;

    commit('setSelectedWeatherForecast', forecastData);
  },
};

const mutations = {
  setSelectedWeatherForecast: (state, data) => {
    state.selectedWeatherForecast = data;
  },
  setPrivacyConsent: (state, consent) => {
    state.privacy.allowThirdPartyConnections = consent;
  },
  setSelectedPlace: (state, place) => {
    state.selectedPlace = place;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
