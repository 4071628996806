/*
  eslint no-shadow: ["error", { "allow": ["state"] }]
*/

import categoriesAPI from '@/api/categories';

const state = {
  categories: [],
  categoryStats: [],
};

const getters = {
  categories: (state) => state.categories,
  categoryByPathName: (state) => (pathName) => {
    console.log();
    return state.categories.find((category) => {
      console.log();
      return category.pathName === pathName;
    });
  },
  categoryById: (state) => (categoryId) => {
    console.log();
    return state.categories.find((category) => {
      console.log();
      return category.id === categoryId;
    });
  },
  categoryStatsById: (state) => (categoryId) => {
    console.log();
    const result = state.categoryStats.find((category) => {
      console.log();
      return category.id === categoryId;
    });

    if (!result) {
      return {
        count: 0,
        id: 0,
      };
    }

    return result;
  },
};

const actions = {
  async fetchCategories({ commit }) {
    const categories = await categoriesAPI.getCategories();

    let stripe = 0;

    commit('setCategories', categories?.data?.map((category, count) => {
      stripe++;

      if (stripe === 5) {
        stripe = 0;
      }

      return {
        id: category.id,
        name: category.name,
        pathName: category.path,
        categoryId: category.id,
        color: `stripe-${stripe}`,
        showInMenu: category.showInMenu,
        type: category.type,
      };
    }) ?? []);
    // todo: find saved posts in local storage and then set them
    // commit('setCategories', [{
    //   id: '84033c9e-05df-4ad4-soae-848c847002fc',
    //   name: 'Allgemein',
    //   pathName: 'allgemein',
    //   categoryId: 1,
    //   color: 'stripe-1',
    // }, {
    //   id: '84033c9e-05df-4ad4-b6d3-848c847002fc',
    //   name: 'Blaulicht',
    //   pathName: 'blaulicht',
    //   categoryId: 9,
    //   color: 'stripe-1',
    // }, {
    //   id: '9dc1b502-4b74-4d74-8333-11eace4c9e58',
    //   name: 'Sport',
    //   pathName: 'sport',
    //   categoryId: 10,
    //   color: 'stripe-2',
    // }, {
    //   id: 'a38716ac-7c9f-48da-87ca-c47adc7bb8d6',
    //   name: 'Kultur',
    //   pathName: 'kultur',
    //   categoryId: 11,
    //   color: 'stripe-3',
    // }, {
    //   id: '0c44e8aa-1612-481e-afa3-9d9dfed42507',
    //   name: 'Politik',
    //   pathName: 'politik',
    //   categoryId: 12,
    //   color: 'stripe-4',
    // }, {
    //   id: 'e54ed339-1b28-492b-9bbf-619fb245d402',
    //   name: 'Wirtschaft',
    //   pathName: 'wirtschaft',
    //   categoryId: 13,
    //   color: 'stripe-1',
    // }, {
    //   id: '57853ce3-77d0-4ba2-8486-34439c9d3366',
    //   name: 'Schützenfest',
    //   pathName: 'schuetzenfest',
    //   categoryId: 14,
    //   color: 'stripe-2',
    // }, {
    //   id: '1f268e74-c95f-415f-8ab5-a6f7ab66a2c6',
    //   name: 'Vereinsleben',
    //   pathName: 'vereinsleben',
    //   categoryId: 15,
    //   color: 'stripe-3',
    // }, {
    //   id: 'a3187704-d6e9-47aa-93ea-646dbea9d770',
    //   name: 'Dies und das',
    //   pathName: 'dies-und-das',
    //   categoryId: 16,
    //   color: 'stripe-4',
    // }, {
    //   id: 'a38716ac-4ba2-47aa-93ea-a6f7ab66a2c6',
    //   name: 'Kinder-Nachrichten',
    //   pathName: 'kinder-nachrichten',
    //   categoryId: 32,
    //   color: 'stripe-2',
    // }, {
    //   id: 'a38716ac-4ba2-47aa-47aa-b66a2a9d770',
    //   name: 'Ratgeber Immobilien',
    //   pathName: 'ratgeber-immobilien',
    //   categoryId: 36,
    //   color: 'stripe-4',
    // }, {
    //   id: 'a38704ac-47aa-415f-05df-b66a2a9d770',
    //   name: 'Ratgeber KFZ',
    //   pathName: 'ratgeber-kfz',
    //   categoryId: 43,
    //   color: 'stripe-1',
    // }, {
    //   id: '57853ce3-47aa-415f-415f-b66a2a9d770',
    //   name: 'Ratgeber Steuern',
    //   pathName: 'ratgeber-steuern',
    //   categoryId: 37,
    //   color: 'stripe-3',
    // }, {
    //   id: '57853ce3-47aa-415f-415f-b66asisa9d770',
    //   name: 'Ratgeber Reisen',
    //   pathName: 'ratgeber-reisen',
    //   categoryId: 34,
    //   color: 'stripe-1',
    // }, {
    //   id: '49349fjr4-47aa-415f-415f-b66asisa9d770',
    //   name: 'Ratgeber Versicherungen',
    //   pathName: 'ratgeber-versicherungen',
    //   categoryId: 35,
    //   color: 'stripe-2',
    // }, {
    //   id: '49349fjr4-47aa-4u9r-415f-b66asisa9d770',
    //   name: 'Ratgeber Senioren',
    //   pathName: 'ratgeber-senioren',
    //   categoryId: 38,
    //   color: 'stripe-3',
    // }, {
    //   id: '49349fjr4-4389-4u9r-415f-b66asisa9d770',
    //   name: 'Ratgeber Recht',
    //   pathName: 'ratgeber-recht',
    //   categoryId: 39,
    //   color: 'stripe-3',
    // }, {
    //   id: '49349fjr4-47aa-4u9r-9785-b66asisa9d770',
    //   name: 'Ratgeber Medizin',
    //   pathName: 'ratgeber-medizin',
    //   categoryId: 40,
    //   color: 'stripe-1',
    // }, {
    //   id: '32093239-47aa-4u9r-9785-b66asisa9d770',
    //   name: 'Ratgeber Bauen',
    //   pathName: 'ratgeber-bauen',
    //   categoryId: 41,
    //   color: 'stripe-4',
    // }, {
    //   id: '32093239-47aa-4u9r-9785-hufd09e3ekf',
    //   name: 'Ratgeber Trauer',
    //   pathName: 'ratgeber-trauer',
    //   categoryId: 63,
    //   color: 'stripe-2',
    // }, {
    //   id: '32093239-47aa-4u9r-2496-b66aaisa9d770',
    //   name: 'A45',
    //   pathName: 'a45',
    //   categoryId: 52,
    //   color: 'stripe-3',
    // }]);
  },
  async fetchCategoryStats({ commit }) {
    const response = await fetch('https://redaktion.lokaldirekt.de/wp-json/wp/v2/categories?per_page=100');

    let categories = await response.json();

    categories = categories.map((category) => {
      console.log();
      return {
        id: category.id,
        count: category.count,
      };
    });

    commit('setCategoryStats', categories);
  },
};

const mutations = {
  setCategories: (state, categories) => {
    state.categories = categories;
  },
  setCategoryStats: (state, categories) => {
    state.categoryStats = categories;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
