import { createI18n } from 'vue-i18n';
import messages from '../lang/de/de';
import datetimeFormats from '../lang/de/date-time-formats';

const i18n = createI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages,
  datetimeFormats,
});

export default i18n;

// const loadedLanguages = ['de'];
