/*
  eslint no-shadow: ["error", { "allow": ["state"] }]
*/
import { Preferences } from '@capacitor/preferences';

const state = {
  myAppWidgets: [],
};

const getters = {
  myAppWidgets: (state) => state.myAppWidgets,
};

const actions = {
  async setMyAppWidgets({ commit }, widgets) {
    await Preferences.set({
      key: 'myapp-widgets',
      value: JSON.stringify(widgets),
    });

    commit('setMyAppWidgets', widgets);
  },
  async fetchMyAppWidgets({ commit }) {
    const { value } = await Preferences.get({ key: 'myapp-widgets' });

    const widgets = value !== null ? JSON.parse(value) : [];

    commit('setMyAppWidgets', widgets);
  },
};

const mutations = {
  setMyAppWidgets: (state, widgets) => {
    state.myAppWidgets = widgets;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
