<template>
  <div class="download-app-hint" :class="!env.isApp && env.showDownloadHint ? 'show' : ''">
    <div class="close-button" @click="setDownloadHintVisibility(false)">
      <icon-times/>
    </div>
    <div class="hint-inner">
      <div class="logo-wrapper">
        <div class="logo">
          <img src="../assets/img/logos/lokaldirekt-logo.svg" class="logo-light">
          <img src="../assets/img/logos/lokaldirekt-logo-dark.svg" class="logo-dark">
        </div>
      </div>

      <p>Jetzt LokalDirekt als App laden und immer auf dem Laufenden sein.</p>
      <div class="download-badges app-store">
        <div class="badge">
          <a href="https://apps.apple.com/de/app/lokaldirekt/id1581666605" target="_blank" rel="noopener">
            <img src="../assets/img/app-store-badges/apple.svg">
          </a>
        </div>
        <div class="badge google-play">
          <a href="https://play.google.com/store/apps/details?id=de.lokaldirekt.app" target="_blank" rel="noopener">
            <img src="../assets/img/app-store-badges/google-play.png">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import IconTimes from '@/components/icons/IconTimes.vue';

export default {
  name: 'DownloadAppHint',
  props: {
  },
  components: {
    IconTimes,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(['env']),
  },
  methods: {
    ...mapActions(['setDownloadHintVisibility']),
  },
};
</script>

<style scoped>
.ld-logo {
  width: 5em;
  margin-bottom: 2em;
}

.download-app-hint {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 8888;
  background: var(--color-header);
  height: 250px;
  width: 100vw;
  transition: 0.4s;
  transform: translateY(250px);
  will-change: transform;
  padding: var(--default-padding);
  text-align: center;
  border-top: 1px solid var(--color-border);
  border-top-left-radius: 0.8em;
  border-top-right-radius: 0.8em;
}

.download-app-hint.show {
  transform: translateY(0px);
}

.close-button {
  position: absolute;
  top: 0.5em;
  right: 1em;
  font-size: 1.2em;
  transition: 0.2s;
  cursor: pointer;
}

.close-button:hover {
  color: var(--color-primary);
}

.logo {
  max-width: 4em;
  margin-bottom: 0.5em;
  text-align: center;
}

.download-badges {
  display: flex;
  flex: 50%;
  justify-content: space-around;
}

.badge img {
  max-width: 8em;
}

.badge.google-play img {
  margin-top: -0.4em;
  height: 54px;
}

@media (min-width: 800px) {
  .download-app-hint {
    width: 400px;
    bottom: 0;
    left: 0;
    border-right: 1px solid var(--color-border);
  }
}

.logo-dark {
  display: none !important;
  text-align: center;
}

.logo-light {
  display: block !important;
  text-align: center;
}

.darkmode .logo-dark {
  display: block !important;
}

.darkmode .logo-light {
  display: none !important;
}

.logo-wrapper {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
