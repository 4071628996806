/*
  eslint no-shadow: ["error", { "allow": ["state"] }]
*/

import placesAPI from '@/api/places';

const state = {
  places: [],
};

const getters = {
  places: (state) => state.places,
  // placeByPathName: (state) => (pathName) => state.places.find((place) => place.pathName === pathName),
  placeByPathName: (state) => (pathName) => {
    console.log();
    return state.places.find((place) => {
      console.log();
      return place.pathName === pathName;
    });
  },
};

const actions = {
  async fetchPlaces({ commit }) {
    // todo: find saved posts in local storage and then set them
    const places = await placesAPI.getPlaces();

    let stripe = 0;

    commit('setPlaces', places?.data?.map((place, count) => {
      stripe++;

      if (stripe === 5) {
        stripe = 0;
      }

      return {
        id: place.id,
        name: place.name,
        pathName: place.path,
        categoryId: place.id,
        color: `stripe-${stripe}`,
        showInMenu: place.showInMenu,
        allowWeatherForecast: place.allowWeatherForecast,
        lat: place.lat,
        lon: place.lon,
      };
    }) ?? []);

    // commit('setPlaces', [{
    //   id: 'a3d1436b-1714-4808-867f-cd112978fd01',
    //   name: 'Halver',
    //   pathName: 'halver',
    //   categoryId: 2,
    //   color: 'stripe-1',
    // }, {
    //   id: '24b01545-de4d-4b42-97f4-af29afd1411b',
    //   name: 'Schalksmühle',
    //   pathName: 'schalksmuehle',
    //   categoryId: 3,
    //   color: 'stripe-2',
    // }, {
    //   id: '33e14408-748a-4063-b868-2b5e07197559',
    //   name: 'Kierspe',
    //   pathName: 'kierspe',
    //   categoryId: 4,
    //   color: 'stripe-3',
    // }, {
    //   id: '2fce1b9d-b66b-4572-9529-3e003eed8374',
    //   name: 'Meinerzhagen',
    //   pathName: 'meinerzhagen',
    //   categoryId: 5,
    //   color: 'stripe-4',
    // }, /* {
    //   id: '6b97f644-326e-4e36-b348-7d1b00dc6b57',
    //   name: 'Herscheid',
    //   pathName: 'herscheid',
    //   categoryId: 6,
    //   color: 'stripe-1',
    // }, {
    //   id: '34f6838a-75be-41cc-b05b-a33f376b143a',
    //   name: 'Plettenberg',
    //   pathName: 'plettenberg',
    //   categoryId: 7,
    //   color: 'stripe-2',
    // }, */ {
    //   id: '7fc2a3e3-7ca1-4c53-8000-bd39518f7e80',
    //   name: 'Lüdenscheid',
    //   pathName: 'luedenscheid',
    //   categoryId: 8,
    //   color: 'stripe-3',
    // }, {
    //   id: '2fce1b9d-7ca1-4c53-97f4-bd39518f7e80',
    //   name: 'Märkischer Kreis',
    //   pathName: 'maerkischer-kreis',
    //   categoryId: 28,
    //   color: 'stripe-1',
    // }, {
    //   id: 'r483848348943dkkjdfdf',
    //   name: 'Nachrodt-Wiblingwerde',
    //   pathName: 'nachrodt-wiblingwerde',
    //   categoryId: 67,
    //   color: 'stripe-2',
    // }, {
    //   id: 'r48384834894dkokdklds3dkkjdfdf',
    //   name: 'Breckerfeld',
    //   pathName: 'breckerfeld',
    //   categoryId: 68,
    //   color: 'stripe-4',
    // }, {
    //   id: 'r4838483489dfoopdfopfkdklds3dkkjdfdf',
    //   name: 'Herscheid',
    //   pathName: 'herscheid',
    //   categoryId: 6,
    //   color: 'stripe-2',
    // },  {
    //   id: 'r483848348sdopsdpfopfkdklds3dkkjdfdf',
    //   name: 'Plettenberg',
    //   pathName: 'plettenberg',
    //   categoryId: 7,
    //   color: 'stripe-3',
    // }]);
  },
};

const mutations = {
  setPlaces: (state, places) => {
    state.places = places;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
