/*
  eslint no-shadow: ["error", { "allow": ["state"] }]
*/
const state = {
  jobs: [],
  selectedJob: {
    id: '',
    title: '',
    pathName: '',
    logo: '',
    company: '',
    applicationDeadline: '',
    contactPerson: '',
    places: [],
    categories: [{
      name: '',
    }],
    coverImage: '',
    coverCaption: '',
    content: '',
  },
};

const getters = {
  jobs: (state) => state.jobs,
  selectedJob: (state) => state.selectedJob,
};

const actions = {
  async fetchJobs({ commit }) {
    const response = await fetch('https://redaktion.lokaldirekt.de/wp-json/wp/v2/stellenangebote?_embed');

    let jobs = await response.json();

    jobs = jobs.map((job) => {
      let coverImage = null;
      let places = [];
      let categories = [];

      // eslint-disable-next-line no-underscore-dangle
      if (!job._embedded) {
        // eslint-disable-next-line no-underscore-dangle, no-param-reassign
        job._embedded = {
        };
      }

      // eslint-disable-next-line no-underscore-dangle
      if (job._embedded['wp:featuredmedia']) {
        // eslint-disable-next-line no-underscore-dangle
        coverImage = job._embedded['wp:featuredmedia'][0].source_url;
      }

      // eslint-disable-next-line no-underscore-dangle
      if (job._embedded['wp:term']) {
        // eslint-disable-next-line no-underscore-dangle
        if (job._embedded['wp:term'].length >= 3) {
          // eslint-disable-next-line no-underscore-dangle
          categories = job._embedded['wp:term'][0].map((category) => ({
            categoryId: category.id,
            name: category.name,
            pathName: category.slug,
          }));

          // eslint-disable-next-line no-underscore-dangle
          places = job._embedded['wp:term'][2].map((place) => ({
            categoryId: place.id,
            name: place.name,
            pathName: place.slug,
          }));
        }
      }

      // COVER CAPTION
      let coverCaption = null;

      if (job.acf.cover_caption) {
        coverCaption = job.acf.cover_caption;
      }

      // LOGO
      let logo = null;

      if (job.acf.logo) {
        logo = job.acf.logo;
      }

      // COMPANY
      let company = null;

      if (job.acf.company_info) {
        company = job.acf.company_info;
      }

      // APPLICATION DEADLINE
      let applicationDeadline = null;

      if (job.acf.application_deadline) {
        applicationDeadline = job.acf.application_deadline;
      }

      // CONTACT PERSON
      let contactPerson = null;

      if (job.acf.contact_person) {
        contactPerson = job.acf.contact_person;
      }

      return {
        id: job.id,
        title: job.title.rendered,
        logo,
        company,
        applicationDeadline,
        contactPerson,
        places,
        categories,
        mainCategory: categories[0],
        coverImage,
        coverCaption,
        pathName: `/job/${job.slug}`,
        content: job.content.rendered,
      };
    });

    commit('setJobs', jobs);
  },
  async fetchJobByPathName({ commit }, pathName) {
    const response = await fetch(`https://redaktion.lokaldirekt.de/wp-json/wp/v2/stellenangebote/?slug=${pathName}&_embed`);

    const job = await response.json();

    if (job.length === 0) {
      return;
    }

    let categories = [];
    // eslint-disable-next-line no-underscore-dangle
    if (job[0]._embedded['wp:term']) {
      // eslint-disable-next-line no-underscore-dangle
      const wpCategories = job[0]._embedded['wp:term'][0];

      categories = wpCategories.map((category) => ({ id: category.id, name: category.name }));
    }

    let coverImage = '';

    // eslint-disable-next-line no-underscore-dangle
    if (job[0]._embedded['wp:featuredmedia']) {
      // eslint-disable-next-line no-underscore-dangle
      coverImage = job[0]._embedded['wp:featuredmedia'][0].source_url;
    }

    // COVER CAPTION
    let coverCaption = null;

    if (job[0].acf.cover_caption) {
      coverCaption = job[0].acf.cover_caption;
    }

    // LOGO
    let logo = null;

    if (job[0].acf.logo) {
      logo = job[0].acf.logo;
    }

    // COMPANY
    let company = null;

    if (job[0].acf.company_info) {
      company = job[0].acf.company_info;
    }

    // APPLICATION DEADLINE
    let applicationDeadline = null;

    if (job[0].acf.application_deadline) {
      applicationDeadline = job[0].acf.application_deadline;
    }

    // CONTACT PERSON
    let contactPerson = null;

    if (job[0].acf.contact_person) {
      contactPerson = job[0].acf.contact_person;
    }

    commit('setSelectedJob', {
      id: job[0].id,
      title: job[0].title.rendered,
      logo,
      company,
      applicationDeadline,
      contactPerson,
      categories,
      mainCategory: categories[0],
      // eslint-disable-next-line no-underscore-dangle
      coverImage,
      coverCaption,
      pathName: job[0].slug,
      content: job[0].content.rendered,
    });
  },
};

const mutations = {
  setJobs: (state, jobs) => {
    state.jobs = jobs;
  },
  setSelectedJob: (state, job) => {
    state.selectedJob = job;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
