let h = [];
function l(a = null, t = {}) {
  h.forEach((n) => {
    n.event === a && n.callback(t);
  });
}
function k(a = null, t = null) {
  a && t && h.push({ event: a, callback: t });
}
let o = {};
const s = { set(a, t) {
  let n = t;
  return typeof t == "object" && (n = JSON.stringify(t)), localStorage.setItem(a, n);
}, get(a) {
  let t = localStorage.getItem(a);
  try {
    t = JSON.parse(t);
  } finally {
    return t;
  }
}, remove(a) {
  localStorage.removeItem(a);
} };
async function f(a, t = {}) {
  return (await fetch(a, { headers: t == null ? void 0 : t.headers })).json();
}
const u = (a, t = {}) => {
  let n = "";
  if (n = new URL(a), t != null && t.params && (n.pathname = n.pathname.replace(/:\w+/g, (r) => {
    let e = "", i = r.split(":")[1];
    return e = t.params[i], e;
  })), t == null ? void 0 : t.query)
    for (let r in t.query)
      n.searchParams.append(g(r, t == null ? void 0 : t.transformQuery), t.query[r]);
  return n.href;
};
function g(a, t) {
  let n = "";
  return n = t === "kebab-case" ? function(r) {
    return r.replace(/[A-Z]/g, "-$&").toLowerCase();
  }(a) : a, n;
}
async function j(a) {
  const t = u(`${o.apiURL}/v1/auth/token`, { query: { appId: a == null ? void 0 : a.appId, projectId: a == null ? void 0 : a.projectId } }), n = await async function(r, e = {}) {
    let i = {};
    return typeof (e == null ? void 0 : e.body) == "object" && (i = JSON.stringify(e.body)), (await fetch(r, { method: "POST", mode: "cors", headers: e == null ? void 0 : e.headers, body: i })).json();
  }(t, { headers: { "Content-Type": "application/json" }, body: a });
  if ((n == null ? void 0 : n.type) === "success")
    return n;
  reject(n);
}
async function m(a) {
  const t = u(`${o.apiURL}/v1/auth/token/revoke`, { query: { appId: a == null ? void 0 : a.appId, projectId: a == null ? void 0 : a.projectId } }), n = await async function(r, e = {}) {
    let i = {};
    return typeof (e == null ? void 0 : e.body) == "object" && (i = JSON.stringify(e.body)), (await fetch(r, { method: "PATCH", mode: "cors", headers: e == null ? void 0 : e.headers, body: i })).json();
  }(t, { headers: { "Content-Type": "application/json" }, body: a });
  if ((n == null ? void 0 : n.type) === "success")
    return n;
  reject(n);
}
let c = "unauthenticated";
async function T(a = {}) {
  var t;
  return t = { ...a, authDataStorageId: `identity:authData:${a == null ? void 0 : a.appId}` }, o = { appId: (t == null ? void 0 : t.appId) ?? null, projectId: (t == null ? void 0 : t.projectId) ?? null, apiURL: (t == null ? void 0 : t.apiURL) ?? "https://api.identity.sgntr.io", frontendURL: (t == null ? void 0 : t.frontendURL) ?? "https://identity.sgntr.io", authDataStorageId: (t == null ? void 0 : t.authDataStorageId) ?? "identity:authData" }, await I();
}
function b() {
  return `${o.frontendURL}/embedded/auth?app-id=${o.appId}&project-id=${o.projectId}`;
}
function D() {
  return `${o.frontendURL}/embedded/profile?app-id=${o.appId}&project-id=${o.projectId}`;
}
async function S(a) {
  return y(a);
}
async function y(a) {
  var t;
  try {
    const n = (t = await j({ ...a, method: (a == null ? void 0 : a.method) ?? "authorization-code", appId: o.appId, projectId: o.projectId })) == null ? void 0 : t.data;
    return w({ ...n, personId: a == null ? void 0 : a.personId }), c = "authenticated", l("auth-state", { status: c }), n;
  } catch (n) {
    console.log("Failed to authenticate with auth code", n);
  }
}
function w(a) {
  s.set(o.authDataStorageId, a);
}
function L() {
  return s.get(o == null ? void 0 : o.authDataStorageId);
}
async function d() {
  const a = s.get(o == null ? void 0 : o.authDataStorageId);
  m({ appId: o.appId, projectId: o.projectId, refreshToken: a.refreshToken }), s.remove(o.authDataStorageId), I();
}
async function p() {
  return new Promise(async (a) => {
    try {
      const t = s.get(o == null ? void 0 : o.authDataStorageId);
      let n = null;
      if (!(t != null && t.expiration))
        return a(null);
      const r = new Date(t.expiration);
      if (/* @__PURE__ */ new Date() > r) {
        if (!(t != null && t.refreshTokenExpiration))
          return d(), a(null);
        const e = new Date(t.refreshTokenExpiration);
        if (/* @__PURE__ */ new Date() > e)
          return d(), a(null);
        const i = await y({ method: "refresh-token", refreshToken: t == null ? void 0 : t.refreshToken, personId: t == null ? void 0 : t.personId });
        if (!i)
          return d(), a(null);
        n = (i == null ? void 0 : i.idToken) ?? null;
      }
      n = (t == null ? void 0 : t.idToken) ?? null, a(n);
    } catch {
      a(null);
    }
  });
}
async function I() {
  c = await p() ? "authenticated" : "unauthenticated";
  const t = { status: c };
  return l("auth-state", t), t;
}
async function U() {
  const a = await p(), t = await async function(n) {
    const r = u(`${o.apiURL}/v1/me`), e = await f(r, { headers: { "Content-Type": "application/json", Authorization: `Bearer ${n == null ? void 0 : n.idToken}` } });
    if ((e == null ? void 0 : e.type) === "success")
      return e;
    reject(e);
  }({ idToken: a });
  return t == null ? void 0 : t.data;
}
async function $() {
  const a = await p(), t = await async function(n) {
    const r = u(`${o.apiURL}/v1/me/organizations`), e = await f(r, { headers: { "Content-Type": "application/json", Authorization: `Bearer ${n == null ? void 0 : n.idToken}` } });
    if ((e == null ? void 0 : e.type) === "success")
      return e;
    reject(e);
  }({ idToken: a });
  return t == null ? void 0 : t.data;
}
export {
  y as authenticate,
  S as authenticateWithAuthCode,
  L as getAuthData,
  I as getAuthState,
  b as getAuthURL,
  p as getIDToken,
  $ as getOrganizations,
  D as getProfileURL,
  U as getUser,
  T as init,
  k as on,
  w as saveAuthData,
  d as signOut,
  c as status
};
