/*
  eslint no-shadow: ["error", { "allow": ["state"] }]
*/

import { Preferences } from '@capacitor/preferences';

function removeUnwantedChars(text) {
  return text.replace(/\u2028/g, '');
}

const state = {
  savedPosts: [],
  recentPosts: null,
  relatedPosts: [],
  postsByPlace: [],
  recommendedPosts: [],
  postsByCategory: [],
  postsSearchResults: [],
  selectedPost: {
    id: '',
    title: '',
    pathName: '',
    author: {
      name: '',
    },
    date: '',
    places: [],
    categories: [{
      name: '',
    }],
    mainPlace: {
      name: '',
    },
    postType: {
      name: null,
    },
    showPostType: false,
    coverImage: '',
    coverCaption: '',
    overview: '',
    postHTML: '',
  },
};

const getters = {
  savedPosts: (state) => state.savedPosts,
  recentPosts: (state) => state.recentPosts,
  selectedPost: (state) => state.selectedPost,
  postsSearchResults: (state) => state.postsSearchResults,
  relatedPosts: (state) => (exclude) => {
    console.log();
    let relatedPosts = state.recommendedPosts.filter((post) => {
      console.log();
      return post.id !== exclude;
    });

    relatedPosts = relatedPosts.slice(0, 10);

    return relatedPosts;
  },
  // postsByPlace: (state) => (placeId) => state.postsByPlace[placeId],
  postsByPlace: (state) => (placeId) => {
    console.log();
    return state.postsByPlace[placeId];
  },
  postsByCategory: (state) => (categoryId) => {
    console.log();
    return state.postsByCategory[categoryId];
  },
};

const actions = {
  // eslint-disable-next-line no-unused-vars
  async fetchSavedPosts({ commit }) {
    const { value } = await Preferences.get({
      key: 'saved_posts',
    });

    const savedPosts = value !== null ? JSON.parse(value) : [];

    commit('setSavedPosts', savedPosts);
  },
  async setSavedPost({ commit }, post) {
    const { value } = await Preferences.get({
      key: 'saved_posts',
    });

    const savedPosts = value !== null ? JSON.parse(value) : [];

    savedPosts.push({
      id: post.id,
      coverImage: post.coverImage,
      title: post.title,
      pathName: post.pathName,
    });

    const savedPostsStringified = JSON.stringify(savedPosts);

    await Preferences.set({
      key: 'saved_posts',
      value: savedPostsStringified,
    });

    commit('setSavedPosts', savedPosts);
  },
  async removeSavedPost({ commit }, id) {
    const { value } = await Preferences.get({
      key: 'saved_posts',
    });

    let savedPosts = value !== null ? JSON.parse(value) : [];

    savedPosts = savedPosts.filter((post) => post.id !== id);

    const savedPostsStringified = JSON.stringify(savedPosts);

    await Preferences.set({
      key: 'saved_posts',
      value: savedPostsStringified,
    });

    commit('setSavedPosts', savedPosts);
  },
  async fetchRecentPosts({ commit }, { page }) {
    const postsPage = page !== undefined ? Number(page) + 1 : 1;
    const response = await fetch(`https://redaktion.lokaldirekt.de/wp-json/wp/v2/posts?_embed&per_page=25&page=${postsPage}`);

    // if (query) {
    //   response = await fetch(`https://redaktion.lokaldirekt.de/wp-json/wp/v2/posts?_embed&page=${query.page}`);
    // } else {
    //   response = await fetch('https://redaktion.lokaldirekt.de/wp-json/wp/v2/posts?_embed');
    // }

    let posts = await response.json();

    posts = posts.map((post) => {
      let coverImage = null;
      let thumbnail = null;
      let places = [];
      let categories = [];

      // eslint-disable-next-line no-underscore-dangle
      if (post._embedded['wp:featuredmedia']) {
        // eslint-disable-next-line no-underscore-dangle
        coverImage = post._embedded['wp:featuredmedia'][0].source_url;

        const optimizedCoverURL = post._embedded['wp:featuredmedia'][0].media_details?.sizes?.medium_large?.source_url ?? '';

        if (optimizedCoverURL) {
          coverImage = optimizedCoverURL;
        }

        thumbnail = post._embedded['wp:featuredmedia'][0].media_details?.sizes?.medium_large?.source_url ?? '';

        if (!thumbnail) {
          thumbnail = coverImage;
        }
      }

      // eslint-disable-next-line no-underscore-dangle
      if (post._embedded['wp:term']) {
        // eslint-disable-next-line no-underscore-dangle
        if (post._embedded['wp:term'].length >= 3) {
          // eslint-disable-next-line no-underscore-dangle
          categories = post._embedded['wp:term'][0].map((category) => ({
            categoryId: category.id,
            name: category.name,
            pathName: category.slug,
          }));

          // eslint-disable-next-line no-underscore-dangle
          places = post._embedded['wp:term'][2].map((place) => ({
            categoryId: place.id,
            name: place.name,
            pathName: place.slug,
          }));
        }
      }

      // COVER CAPTION
      let coverCaption = null;

      if (post.acf.cover_caption) {
        coverCaption = removeUnwantedChars(post.acf.cover_caption);
      }

      // COVER POSITION
      let coverPosition = 'center-middle';

      if (post.acf.cover_image_position) {
        coverPosition = removeUnwantedChars(post.acf.cover_image_position);
      }

      // HIDE OVERVIEW
      let hideOverview = false;

      if (post.acf.hide_overview) {
        hideOverview = Boolean(post.acf.hide_overview);
      }

      return {
        id: post.id,
        title: removeUnwantedChars(post.title.rendered),
        author: {
          id: post.author,
          // eslint-disable-next-line no-underscore-dangle
          name: post._embedded.author[0].name,
        },
        date: new Date(post.date).toISOString(),
        places,
        categories,
        mainCategory: categories[0],
        mainPlace: places[0],
        coverImage,
        coverPosition,
        coverCaption,
        thumbnail,
        hideOverview,
        pathName: `/news/${post.slug}`,
        overview: removeUnwantedChars(post.excerpt.rendered),
        content: removeUnwantedChars(post.content.rendered),
      };
    });

    commit('setRecentPosts', posts);
  },
  async fetchRecommendedPosts({ commit }, { page }) {
    const postsPage = page !== undefined ? Number(page) + 1 : 1;
    const response = await fetch(`https://redaktion.lokaldirekt.de/wp-json/wp/v2/posts?_embed&per_page=25&page=${postsPage}`);

    // if (query) {
    //   response = await fetch(`https://redaktion.lokaldirekt.de/wp-json/wp/v2/posts?_embed&page=${query.page}`);
    // } else {
    //   response = await fetch('https://redaktion.lokaldirekt.de/wp-json/wp/v2/posts?_embed');
    // }

    let posts = await response.json();

    posts = posts.map((post) => {
      let coverImage = null;
      let thumbnail = null;
      let places = [];
      let categories = [];

      // eslint-disable-next-line no-underscore-dangle
      if (post._embedded['wp:featuredmedia']) {
        // eslint-disable-next-line no-underscore-dangle
        coverImage = post._embedded['wp:featuredmedia'][0].source_url;

        const optimizedCoverURL = post._embedded['wp:featuredmedia'][0].media_details?.sizes?.medium_large?.source_url ?? '';

        if (optimizedCoverURL) {
          coverImage = optimizedCoverURL;
        }

        thumbnail = post._embedded['wp:featuredmedia'][0].media_details?.sizes?.medium_large?.source_url ?? '';

        if (!thumbnail) {
          thumbnail = coverImage;
        }
      }

      // eslint-disable-next-line no-underscore-dangle
      if (post._embedded['wp:term']) {
        // eslint-disable-next-line no-underscore-dangle
        if (post._embedded['wp:term'].length >= 3) {
          // eslint-disable-next-line no-underscore-dangle
          categories = post._embedded['wp:term'][0].map((category) => ({
            categoryId: category.id,
            name: category.name,
            pathName: category.slug,
          }));

          // eslint-disable-next-line no-underscore-dangle
          places = post._embedded['wp:term'][2].map((place) => ({
            categoryId: place.id,
            name: place.name,
            pathName: place.slug,
          }));
        }
      }

      // COVER CAPTION
      let coverCaption = null;

      if (post.acf.cover_caption) {
        coverCaption = removeUnwantedChars(post.acf.cover_caption);
      }

      // COVER POSITION
      let coverPosition = 'center-middle';

      if (post.acf.cover_image_position) {
        coverPosition = removeUnwantedChars(post.acf.cover_image_position);
      }

      // HIDE OVERVIEW
      let hideOverview = false;

      if (post.acf.hide_overview) {
        hideOverview = Boolean(post.acf.hide_overview);
      }

      return {
        id: post.id,
        title: removeUnwantedChars(post.title.rendered),
        author: {
          id: post.author,
          // eslint-disable-next-line no-underscore-dangle
          name: post._embedded.author[0].name,
        },
        date: new Date(post.date).toISOString(),
        places,
        categories,
        mainCategory: categories[0],
        mainPlace: places[0],
        coverImage,
        coverPosition,
        coverCaption,
        thumbnail,
        hideOverview,
        pathName: `/news/${post.slug}`,
        overview: removeUnwantedChars(post.excerpt.rendered),
        content: removeUnwantedChars(post.content.rendered),
      };
    });

    commit('setRecommendedPosts', posts);
  },
  async fetchMoreRecentPosts({ commit }, { page }) {
    const postsPage = page + 1;
    const response = await fetch(`https://redaktion.lokaldirekt.de/wp-json/wp/v2/posts?_embed&per_page=25&page=${postsPage}`);

    // if (query) {
    //   response = await fetch(`https://redaktion.lokaldirekt.de/wp-json/wp/v2/posts?_embed&page=${query.page}`);
    // } else {
    //   response = await fetch('https://redaktion.lokaldirekt.de/wp-json/wp/v2/posts?_embed');
    // }

    let posts = await response.json();

    posts = posts.map((post) => {
      let coverImage = null;
      let thumbnail = null;
      let places = [];
      let categories = [];

      // eslint-disable-next-line no-underscore-dangle
      if (post._embedded['wp:featuredmedia']) {
        // eslint-disable-next-line no-underscore-dangle
        coverImage = post._embedded['wp:featuredmedia'][0].source_url;

        const optimizedCoverURL = post._embedded['wp:featuredmedia'][0].media_details?.sizes?.medium_large?.source_url ?? '';

        if (optimizedCoverURL) {
          coverImage = optimizedCoverURL;
        }

        thumbnail = post._embedded['wp:featuredmedia'][0].media_details?.sizes?.medium_large?.source_url ?? '';

        if (!thumbnail) {
          thumbnail = coverImage;
        }
      }

      // eslint-disable-next-line no-underscore-dangle
      if (post._embedded['wp:term']) {
        // eslint-disable-next-line no-underscore-dangle
        if (post._embedded['wp:term'].length >= 3) {
          // eslint-disable-next-line no-underscore-dangle
          categories = post._embedded['wp:term'][0].map((category) => ({
            categoryId: category.id,
            name: category.name,
            pathName: category.slug,
          }));

          // eslint-disable-next-line no-underscore-dangle
          places = post._embedded['wp:term'][2].map((place) => ({
            categoryId: place.id,
            name: place.name,
            pathName: place.slug,
          }));
        }
      }

      // COVER CAPTION
      let coverCaption = null;

      if (post.acf.cover_caption) {
        coverCaption = removeUnwantedChars(post.acf.cover_caption);
      }

      // COVER POSITION
      let coverPosition = 'center-middle';

      if (post.acf.cover_image_position) {
        coverPosition = removeUnwantedChars(post.acf.cover_image_position);
      }

      // HIDE OVERVIEW
      let hideOverview = false;

      if (post.acf.hide_overview) {
        hideOverview = Boolean(post.acf.hide_overview);
      }

      return {
        id: post.id,
        title: removeUnwantedChars(post.title.rendered),
        author: {
          id: post.author,
          // eslint-disable-next-line no-underscore-dangle
          name: post._embedded.author[0].name,
        },
        date: new Date(post.date).toISOString(),
        places,
        categories,
        mainCategory: categories[0],
        mainPlace: places[0],
        coverImage,
        coverPosition,
        coverCaption,
        thumbnail,
        hideOverview,
        pathName: `/news/${post.slug}`,
        overview: removeUnwantedChars(post.excerpt.rendered),
        content: removeUnwantedChars(post.content.rendered),
      };
    });

    commit('addRecentPosts', posts);
  },
  async fetchPostsByPlace({ commit }, query) {
    if (query.page === undefined) {
      // eslint-disable-next-line no-param-reassign
      query.page = 1;
    }
    if (query.placeId === undefined || query.page === undefined) {
      return;
    }

    const { placeId } = query;

    const postsPage = !query.page ? 1 : Number(query.page);

    const response = await fetch(`https://redaktion.lokaldirekt.de/wp-json/wp/v2/posts?_embed&places=${query.placeId}&page=${postsPage}`);

    let posts = await response.json();

    posts = posts.map((post) => {
      let coverImage = null;
      let thumbnail = null;
      let places = [];
      let categories = [];

      // eslint-disable-next-line no-underscore-dangle
      if (post._embedded['wp:featuredmedia']) {
        // eslint-disable-next-line no-underscore-dangle
        coverImage = post._embedded['wp:featuredmedia'][0].source_url;

        const optimizedCoverURL = post._embedded['wp:featuredmedia'][0].media_details?.sizes?.medium_large?.source_url ?? '';

        if (optimizedCoverURL) {
          coverImage = optimizedCoverURL;
        }

        thumbnail = post._embedded['wp:featuredmedia'][0].media_details?.sizes?.medium_large?.source_url ?? '';

        if (!thumbnail) {
          thumbnail = coverImage;
        }
      }

      // eslint-disable-next-line no-underscore-dangle
      if (post._embedded['wp:term']) {
        // eslint-disable-next-line no-underscore-dangle
        if (post._embedded['wp:term'].length >= 3) {
          // eslint-disable-next-line no-underscore-dangle
          places = post._embedded['wp:term'][2].map((place) => ({
            categoryId: place.id,
            name: place.name,
            pathName: place.slug,
          }));
        }
      }

      // eslint-disable-next-line no-underscore-dangle
      if (post._embedded['wp:term']) {
        // eslint-disable-next-line no-underscore-dangle
        if (post._embedded['wp:term'].length >= 3) {
          // eslint-disable-next-line no-underscore-dangle
          categories = post._embedded['wp:term'][0].map((category) => ({
            categoryId: category.id,
            name: category.name,
            pathName: category.slug,
          }));

          // eslint-disable-next-line no-underscore-dangle
          places = post._embedded['wp:term'][2].map((place) => ({
            categoryId: place.id,
            name: place.name,
            pathName: place.slug,
          }));
        }
      }

      // COVER CAPTION
      let coverCaption = null;

      if (post.acf.cover_caption) {
        coverCaption = removeUnwantedChars(post.acf.cover_caption);
      }

      // COVER POSITION
      let coverPosition = 'center-middle';

      if (post.acf.cover_image_position) {
        coverPosition = removeUnwantedChars(post.acf.cover_image_position);
      }

      // HIDE OVERVIEW
      let hideOverview = false;

      if (post.acf.hide_overview) {
        hideOverview = Boolean(post.acf.hide_overview);
      }

      return {
        id: post.id,
        title: removeUnwantedChars(post.title.rendered),
        author: {
          id: post.author,
          // eslint-disable-next-line no-underscore-dangle
          name: post._embedded.author[0].name,
        },
        date: new Date(post.date).toISOString(),
        places,
        coverImage,
        coverPosition,
        coverCaption,
        thumbnail,
        categories,
        hideOverview,
        mainCategory: categories[0],
        mainPlace: places[0],
        pathName: post.slug,
        overview: removeUnwantedChars(post.excerpt.rendered),
        content: removeUnwantedChars(post.content.rendered),
      };
    });

    commit('setPostsByPlace', {
      placeId,
      posts,
    });
  },
  async fetchPostsByCategory({ commit }, categoryId) {
    let query = {};
    if (typeof categoryId === 'object') {
      query = categoryId;
    } else {
      query.categoryId = categoryId;
    }

    if (query.page === undefined) {
      query.page = 1;
    }

    if (query.categoryId === undefined) {
      return;
    }

    const catId = query.categoryId;

    // marker
    const postsPage = !query.page ? 1 : Number(query.page);
    const response = await fetch(`https://redaktion.lokaldirekt.de/wp-json/wp/v2/posts?_embed&categories=${query.categoryId}&page=${postsPage}`);

    let posts = await response.json();

    if (!posts) {
      return;
    }

    posts = posts.map((post) => {
      let coverImage = null;
      let thumbnail = null;
      let places = [];
      let categories = [];

      // eslint-disable-next-line no-underscore-dangle
      if (post._embedded['wp:featuredmedia']) {
        // eslint-disable-next-line no-underscore-dangle
        coverImage = post._embedded['wp:featuredmedia'][0].source_url;

        const optimizedCoverURL = post._embedded['wp:featuredmedia'][0].media_details?.sizes?.medium_large?.source_url ?? '';

        if (optimizedCoverURL) {
          coverImage = optimizedCoverURL;
        }

        thumbnail = post._embedded['wp:featuredmedia'][0].media_details?.sizes?.medium_large?.source_url ?? '';

        if (!thumbnail) {
          thumbnail = coverImage;
        }
      }

      // eslint-disable-next-line no-underscore-dangle
      if (post._embedded['wp:term']) {
        // eslint-disable-next-line no-underscore-dangle
        if (post._embedded['wp:term'].length >= 3) {
          // eslint-disable-next-line no-underscore-dangle
          places = post._embedded['wp:term'][2].map((place) => ({
            categoryId: place.id,
            name: place.name,
            pathName: place.slug,
          }));
        }
      }

      // eslint-disable-next-line no-underscore-dangle
      if (post._embedded['wp:term']) {
        // eslint-disable-next-line no-underscore-dangle
        if (post._embedded['wp:term'].length >= 3) {
          // eslint-disable-next-line no-underscore-dangle
          categories = post._embedded['wp:term'][0].map((category) => ({
            categoryId: category.id,
            name: category.name,
            pathName: category.slug,
          }));

          // eslint-disable-next-line no-underscore-dangle
          places = post._embedded['wp:term'][2].map((place) => ({
            categoryId: place.id,
            name: place.name,
            pathName: place.slug,
          }));
        }
      }

      // COVER CAPTION
      let coverCaption = null;

      if (post.acf.cover_caption) {
        coverCaption = removeUnwantedChars(post.acf.cover_caption);
      }

      // COVER POSITION
      let coverPosition = 'center-middle';

      if (post.acf.cover_image_position) {
        coverPosition = removeUnwantedChars(post.acf.cover_image_position);
      }

      // HIDE OVERVIEW
      let hideOverview = false;

      if (post.acf.hide_overview) {
        hideOverview = Boolean(post.acf.hide_overview);
      }

      return {
        id: post.id,
        title: removeUnwantedChars(post.title.rendered),
        author: {
          id: post.author,
          // eslint-disable-next-line no-underscore-dangle
          name: post._embedded.author[0].name,
        },
        date: new Date(post.date).toISOString(),
        places,
        coverImage,
        coverPosition,
        coverCaption,
        thumbnail,
        categories,
        hideOverview,
        mainCategory: categories[0],
        mainPlace: places[0],
        pathName: post.slug,
        overview: removeUnwantedChars(post.excerpt.rendered),
        content: removeUnwantedChars(post.content.rendered),
      };
    });

    commit('setPostsByCategory', {
      categoryId: catId,
      posts,
    });
  },
  async fetchPostByPathName({ commit }, pathName) {
    const response = await fetch(`https://redaktion.lokaldirekt.de/wp-json/wp/v2/posts/?slug=${pathName}&_embed`);

    const post = await response.json();

    if (post.length === 0) {
      return;
    }

    let places = [];
    let postType = {
      name: null,
    };

    let showPostType = false;

    // eslint-disable-next-line no-underscore-dangle
    const wpCategories = post[0]._embedded['wp:term'][0];

    const categories = wpCategories.map((category) => ({ id: category.id, name: category.name }));

    // eslint-disable-next-line no-underscore-dangle
    if (post[0]._embedded['wp:term']) {
      // eslint-disable-next-line no-underscore-dangle
      if (post[0]._embedded['wp:term'].length >= 3) {
        // eslint-disable-next-line no-underscore-dangle
        places = post[0]._embedded['wp:term'][2].map((place) => ({
          categoryId: place.id,
          name: place.name,
          pathName: place.slug,
        }));

        // eslint-disable-next-line no-underscore-dangle
        if (post[0]._embedded['wp:term'][3].length) {
          // apost type is specified
          // eslint-disable-next-line no-underscore-dangle
          const postTypeConfig = post[0]._embedded['wp:term'][3][0];

          postType = {
            categoryId: postTypeConfig.id,
            name: postTypeConfig.name,
            pathName: postTypeConfig.slug,
          };

          showPostType = true;
        }
      }
    }

    let coverImage = '';

    // eslint-disable-next-line no-underscore-dangle
    if (post[0]._embedded['wp:featuredmedia']) {
      // eslint-disable-next-line no-underscore-dangle
      coverImage = post[0]._embedded['wp:featuredmedia'][0].source_url;

      const optimizedCoverURL = post[0]._embedded['wp:featuredmedia'][0].media_details?.sizes['1536x1536']?.source_url ?? '';

      if (optimizedCoverURL) {
        coverImage = optimizedCoverURL;
      }
    }

    // COVER CAPTION
    let coverCaption = null;

    if (post[0].acf.cover_caption) {
      coverCaption = removeUnwantedChars(post[0].acf.cover_caption);
    }

    // COVER POSITION
    let coverPosition = 'center-middle';

    if (post[0].acf.cover_image_position) {
      coverPosition = removeUnwantedChars(post[0].acf.cover_image_position);
    }

    // IMAGE OVERLAY
    let imageOverlay = '';

    if (post[0].acf.image_overlay) {
      imageOverlay = post[0].acf.image_overlay;
    }

    // HIDE OVERVIEW
    let hideOverview = false;

    if (post[0].acf.hide_overview) {
      hideOverview = Boolean(post[0].acf.hide_overview);
    }

    commit('setSelectedPost', {
      id: post[0].id,
      title: removeUnwantedChars(post[0].title.rendered),
      author: {
        id: post[0].author,
        // eslint-disable-next-line no-underscore-dangle
        name: post[0]._embedded.author[0].name,
      },
      date: new Date(post[0].date).toISOString(),
      places,
      mainPlace: places[0],
      categories,
      mainCategory: categories[0],
      postType,
      showPostType,
      hideOverview,
      // eslint-disable-next-line no-underscore-dangle
      coverImage,
      coverPosition,
      coverCaption,
      imageOverlay,
      pathName: post[0].slug,
      overview: removeUnwantedChars(post[0].excerpt.rendered),
      content: removeUnwantedChars(post[0].content.rendered),
    });
  },
  async fetchPostsBySearchQuery({ commit }, { query }) {
    const searchQuery = query !== undefined ? query : '';
    const response = await fetch(`https://redaktion.lokaldirekt.de/wp-json/wp/v2/posts?_embed&type=post&per_page=50&search=${searchQuery}`);

    // if (query) {
    //   response = await fetch(`https://redaktion.lokaldirekt.de/wp-json/wp/v2/posts?_embed&page=${query.page}`);
    // } else {
    //   response = await fetch('https://redaktion.lokaldirekt.de/wp-json/wp/v2/posts?_embed');
    // }

    let posts = await response.json();

    posts = posts.map((post) => {
      let coverImage = null;
      let thumbnail = null;
      let places = [];
      let categories = [];

      // eslint-disable-next-line no-underscore-dangle
      if (post._links['wp:featuredmedia']) {
        // eslint-disable-next-line no-underscore-dangle
        coverImage = post._embedded['wp:featuredmedia'][0].source_url;

        const optimizedCoverURL = post._embedded['wp:featuredmedia'][0].media_details?.sizes?.medium_large?.source_url ?? '';

        if (optimizedCoverURL) {
          coverImage = optimizedCoverURL;
        }

        thumbnail = post._embedded['wp:featuredmedia'][0].media_details?.sizes?.medium_large?.source_url ?? '';

        if (!thumbnail) {
          thumbnail = coverImage;
        }
      }

      // eslint-disable-next-line no-underscore-dangle
      if (post._embedded['wp:term']) {
        // eslint-disable-next-line no-underscore-dangle
        if (post._embedded['wp:term'].length >= 3) {
          // eslint-disable-next-line no-underscore-dangle
          categories = post._embedded['wp:term'][0].map((category) => ({
            categoryId: category.id,
            name: category.name,
            pathName: category.slug,
          }));

          // eslint-disable-next-line no-underscore-dangle
          places = post._embedded['wp:term'][2].map((place) => ({
            categoryId: place.id,
            name: place.name,
            pathName: place.slug,
          }));
        }
      }

      // COVER CAPTION
      let coverCaption = null;

      // eslint-disable-next-line no-underscore-dangle
      if (post.acf.cover_caption) {
        coverCaption = removeUnwantedChars(post.acf.cover_caption);
      }

      // COVER POSITION
      let coverPosition = 'center-middle';

      if (post.acf.cover_image_position) {
        coverPosition = removeUnwantedChars(post.acf.cover_image_position);
      }

      return {
        id: post.id,
        title: removeUnwantedChars(post.title.rendered),
        author: {
          id: post.author,
          // eslint-disable-next-line no-underscore-dangle
          name: post._embedded.author[0].name,
        },
        date: new Date(post.date).toISOString(),
        places,
        categories,
        mainCategory: categories[0],
        mainPlace: places[0],
        coverImage,
        coverPosition,
        coverCaption,
        thumbnail,
        pathName: `/news/${post.slug}`,
        overview: removeUnwantedChars(post.excerpt.rendered),
        content: removeUnwantedChars(post.content.rendered),
      };
    });

    commit('setPostsSearchResults', posts);
  },
  async clearSearchResults({ commit }) {
    commit('setPostsSearchResults', null);
  },
};

const mutations = {
  setSavedPosts: (state, posts) => {
    state.savedPosts = posts;
  },
  setRecentPosts: (state, posts) => {
    state.recentPosts = posts;
    // [].push.apply(state.recentPosts, posts);
  },
  setRecommendedPosts: (state, posts) => {
    state.recommendedPosts = posts;
    // [].push.apply(state.recentPosts, posts);
  },
  addRecentPosts: (state, posts) => {
    [].push.apply(state.recentPosts, posts);
  },
  setPostsByPlace: (state, { posts, placeId }) => {
    state.postsByPlace[placeId] = posts;
  },
  setPostsByCategory: (state, { posts, categoryId }) => {
    state.postsByCategory[categoryId] = posts;
  },
  setSelectedPost: (state, post) => {
    state.selectedPost = post;
  },
  setPostsSearchResults: (state, posts) => {
    state.postsSearchResults = posts;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
