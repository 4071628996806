import request from '../utility/request';
import generateRequestURL from './generate-request-url';

let apiURL = 'https://redaktion.lokaldirekt.de/wp-json/wp/v2';

export default (path, options = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      // generate request url
      const requestURL = generateRequestURL(`${apiURL}${path}`, options);

      // make request
      let response = null;

      // GET Request
      if (options.method === 'GET') {
        response = await request.get(requestURL, {
        });
      }

      // POST Request
      if (options.method === 'POST') {
        response = await request.post(requestURL, {
          ...options,
        });
      }

      // PATCH Request
      if (options.method === 'PATCH') {
        response = await request.patch(requestURL, {
          ...options,
        });
      }

      // DELETE Request
      if (options.method === 'DELETE') {
        response = await request.delete(requestURL, {
          ...options,
        });
      }

      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};
