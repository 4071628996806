/* eslint-disable no-useless-escape */
import smoothscroll from 'smoothscroll-polyfill';
import { IonicVue } from '@ionic/vue';
import { createApp } from 'vue';
import Vue from 'vue';
import { DisableSwipeBackDirective } from 'v-disable-swipe-back';
import LazyLoadingDirective from '@/directives/lazy-loading';
import App from './App.vue';
// import './registerServiceWorker';
import router from './router';
import store from './store';
import i18n from './i18n';
import lazyLoading from './utility/lazy-loading';

import connect from '@/external/connect.es.min.js';
import * as identity from '@/external/identity.es.min.js';

// import '@ionic/vue/css/core.css';

const app = createApp(App)
  .use(router)
  .use(store)
  .use(i18n)
  .use(IonicVue, {
    rippleEffect: false,
    mode: 'ios',
  })
  .directive('lazy-loading', LazyLoadingDirective)
  .directive('disable-swipe-back', DisableSwipeBackDirective);

router.isReady().then(() => {
  app.mount('#app');
});

router.beforeEach(() => {
  window.scrollTo(0, 0);
  document.title = 'LokalDirekt';
});

smoothscroll.polyfill();

// INIT SIGNATURE CONNECT
connect.init({
  design: {
    fonts: [{ // WOFF2
      family: 'Oswald',
      src: 'https://redaktion.lokaldirekt.de/wp-content/uploads/2021/08/oswald-v36-latin-300.woff2',
      weight: '300',
      format: 'woff2',
    }, {
      family: 'Oswald',
      src: 'https://redaktion.lokaldirekt.de/wp-content/uploads/2021/08/oswald-v36-latin-regular.woff2',
      weight: '400',
      format: 'woff2',
    }, {
      family: 'Oswald',
      src: 'https://redaktion.lokaldirekt.de/wp-content/uploads/2021/08/oswald-v36-latin-600.woff2',
      weight: '600',
      format: 'woff2',
    }],
    variables: {
      // style global elements
      colorPrimary: '#CF2C32',
      colorAccent: '#CF2C32',
      colorBackground: '#FFFFFF',
      colorBackgroundSecondary: '#F6F6F6',
      colorBackgroundTertiary: '#E6E6E6',
      colorText: '#000105',
      colorTextLight: '#ffffff',
      fontSizeBase: '18px',
      fontFamilyBase: 'Oswald',
      fontWeightBase: '400',
      borderRadiusBase: '0.5em',
      fontSizePrimary: '2em',
      fontFamilyPrimary: 'Oswald',
      fontWeightPrimary: '600',
      spaceS: '0.2em',
      spaceBase: '1em',
      uiHeadingPrimaryColor: '#000105',
      uiHeadingPrimaryPaddingB: '0.5em',
      uiHeadingSecondaryColor: '#000105',
      uiHeadingTertiaryColor: '#000105',
    },
  },
});

identity.init({
  projectId: 'PKmDoUrsnuJcsm3MG2Ap9',
  appId: '2HNwaRUjRo7jwWbx8TIwp',
});

// dev
// identity.init({
//   apiURL: 'http://localhost:3010',
//   frontendURL: 'http://localhost:5174',
//   organizationId: 'KR0idVVslX7nhs2ZfCrM_',
//   appId: '1qVHEurb7ZogRPCk3iO93',
//   connect: {
//     instance: connect,
//   },
// });

document.addEventListener('touchstart', () => {

}, {
  passive: true,
});

// setup intersection observer
window.addEventListener('DOMContentLoaded', async () => {
  // lazyLoading.init(document.querySelector('.lazy'));
});

window.onerror = (errorMsg) => {
  console.log('Error occured: ', errorMsg);
  return false;
};
