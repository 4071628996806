/*
  eslint no-shadow: ["error", { "allow": ["state"] }]
*/

const state = {
  industries: [],
};

const getters = {
  industries: (state) => state.industries,
};

const actions = {
  async fetchIndustries({ commit }) {
    // todo: find saved posts in local storage and then set them
    commit('setIndustries', [{
      id: '123445656',
      name: 'Sport',
      path: '/sport',
      categoryId: '',
      color: 'stripe-2',
    }, {
      id: '12324673',
      name: 'Kultur',
      path: '/kultur',
      categoryId: '',
      color: 'stripe-3',
    }, {
      id: '12235458',
      name: 'Politik',
      path: '/politik',
      categoryId: '',
      color: 'stripe-4',
    }]);
  },
};

const mutations = {
  setIndustries: (state, industries) => {
    state.industries = industries;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
