/*
  eslint no-shadow: ["error", { "allow": ["state"] }]
*/

const state = {
  upcomingEvents: [],
  selectedEvent: {
    id: '',
    coverImage: '',
    title: '',
    pathName: '',
    allDay: false,
    startDate: '',
    endDate: '',
    overview: '',
    postHTML: '',
    websiteURL: '',
    organizers: [],
    location: {
      name: '',
      address: '',
      city: '',
      postcode: '',
    },
  },
};

const getters = {
  upcomingEvents: (state) => state.upcomingEvents,
  // recentPosts: (state) => state.recentPosts,
  // selectedPost: (state) => state.selectedPost,
};

const actions = {
  async fetchUpcomingEvents({ commit }) {
    const response = await fetch('https://redaktion.lokaldirekt.de/wp-json/tribe/events/v1/events?_embed');

    let events = await response.json();

    events = events.events;

    events = events.map((event) => {
      let location = {};

      if (event.venue.length !== 0) {
        location = {
          name: event.venue.venue,
          address: event.venue.address,
          city: event.venue.city,
          postcode: event.venue.zip,
        };
      }

      return {
        id: event.id,
        coverImage: '',
        title: event.title,
        pathName: event.slug,
        allDay: event.all_day,
        startDate: new Date(event.utc_start_date),
        endDate: new Date(event.utc_end_date),
        overview: event.excerpt,
        description: event.description,
        websiteURL: event.website,
        organizers: [],
        location,
      };
    });

    commit('setUpcomingEvents', events);
  },
  // async fetchEventById({ commit }, eventId) {
  //   const response = await fetch(`https://redaktion.lokaldirekt.de/wp-json/tribe/events/v1/events/${eventId}`);

  //   const event = await response.json();

  //   if (event.length === 0) {
  //     return;
  //   }

  //   console.log(`EVENT BY ID ${eventId}:`, event);

  //   // eslint-disable-next-line no-underscore-dangle
  //   const wpCategories = event[0]._embedded['wp:term'][0];

  //   const categories = wpCategories.map((category) => ({ id: category.id, name: category.name }));

  //   commit('setSelectedEvent', {
  //     id: post[0].id,
  //     title: post[0].title.rendered,
  //     author: {
  //       id: post[0].author,
  //       // eslint-disable-next-line no-underscore-dangle
  //       name: post[0]._embedded.author[0].name,
  //     },
  //     date: new Date(post[0].date),
  //     places: [{
  //       name: 'Halver',
  //       id: 'halver',
  //     }],
  //     categories,
  //     // eslint-disable-next-line no-underscore-dangle
  //     coverImage: post[0]._embedded['wp:featuredmedia'][0].source_url,
  //     pathName: post[0].slug,
  //     overview: post[0].excerpt.rendered,
  //     content: post[0].content.rendered,
  //   });
  // },
};

const mutations = {
  setUpcomingEvents: (state, events) => {
    state.recentEvents = events;
  },
  setSelectedEvent: (state, event) => {
    state.selectedEvent = event;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
