var g = Object.defineProperty;
var d = (e, t, n) => t in e ? g(e, t, { enumerable: !0, configurable: !0, writable: !0, value: n }) : e[t] = n;
var p = (e, t, n) => (d(e, typeof t != "symbol" ? t + "" : t, n), n);
const o = {
  isInFrame() {
    return window.self !== window.top;
  },
  frame: {
    frameId: "",
    frameKey: null
  },
  url: {
    query: {}
  },
  settings: {},
  design: {},
  platform: "",
  mode: "prod"
}, c = {
  emit(e, t) {
    const n = typeof e == "string" ? e : e == null ? void 0 : e.event;
    o.isInFrame() ? window.parent.postMessage({
      event: `connect-${n}`,
      sender: {
        type: "frame"
      },
      frame: o.frame,
      ...t
    }, "*") : this.notifyListeners("to-frame", {
      options: e,
      payload: t
    });
  },
  notifyListeners(e = null, t = {}) {
    this.listeners.forEach((n) => {
      n.event === e && n.callback(t);
    });
  },
  on(e = null, t = null) {
    !e || !t || this.listeners.push({
      event: e,
      callback: t
    });
  },
  listeners: []
}, m = {
  set(e) {
    var t;
    console.log("set options"), (e == null ? void 0 : e.interceptLinks) !== void 0 && (t = e.interceptLinks) != null && t.ignore && (this.interceptLinks.ignore = e.interceptLinks.ignore, this._prepareIgnoredInterceptedLinkSelectors(this.interceptLinks.ignore));
  },
  interceptLinks: {
    ignore: [],
    ignoredClasses: [],
    ignoredIds: []
  },
  _prepareIgnoredInterceptedLinkSelectors(e) {
    this.interceptLinks.ignoredClasses.at, e.forEach((t) => {
      let n = !1, s = !1, r = t;
      t[0] === "." && (n = !0, r = t.split(".")[1]), t[0] === "#" && (s = !0, r = t.split("#")[1]), n && this.interceptLinks.ignoredClasses.push(r), s && this.interceptLinks.ignoredIds.push(r);
    });
  }
}, L = {
  toKebabCase(e) {
    return e.replace(/[A-Z]/g, "-$&").toLowerCase();
  }
}, y = {
  setDesign(e) {
    e && (this.loadFonts(e == null ? void 0 : e.fonts), this.setAppearanceVariables(e == null ? void 0 : e.variables), console.log("SET DESIGN", e));
  },
  loadFonts(e) {
    e && e.forEach((t) => {
      const n = new FontFace(t.family, `url(${t.src})`);
      document.fonts.add(n);
    });
  },
  setAppearanceVariables(e) {
    const t = document.documentElement;
    for (let [n, s] of Object.entries(e))
      t.style.setProperty(`--${L.toKebabCase(n)}`, s);
  }
}, k = {
  sendFrameHeight() {
    c.emit("frame-height", {
      height: this.calculateFrameHeight()
    });
  },
  sendSetupRequest() {
    c.emit("setup-request", {});
  },
  calculateFrameHeight() {
    const e = document.documentElement, t = document.body;
    return Math.max(t.scrollHeight, t.offsetHeight, e.offsetHeight);
  },
  setupCSSClasses() {
    const e = ["connect-frame"];
    e.forEach(() => {
      document.getElementsByTagName("html")[0].classList.add(e);
    });
  },
  setupStyles() {
  },
  init() {
    this.setupCSSClasses(), this.sendFrameHeight(), this.receiveSetupEvent(), this.sendSetupRequest(), window.addEventListener("message", (e) => {
      var r;
      const t = (r = e == null ? void 0 : e.data) == null ? void 0 : r.event, n = e == null ? void 0 : e.data;
      if (!t)
        return;
      const s = t.split("connect-")[1];
      c.notifyListeners(s, {
        ...n,
        event: s
      });
    }), setInterval(() => {
      this.sendFrameHeight();
    }, 200);
  },
  receiveSetupEvent() {
    window.connect.on("frame-setup", (e) => {
      var t;
      o.settings = e.settings, y.setDesign(e.design), (t = e.settings) != null && t.interceptLinks && (this.setupInterceptedLinks(), this.observeDynamicallyAddedLinks());
    });
  },
  prepareInterceptedLink(e) {
    e.href && (e.dataset.href = e.href, e.href = "");
  },
  setupInterceptedLinks() {
    const e = m.interceptLinks.ignore.map((n) => `:not(${n})`).join(""), t = document.querySelectorAll(`a${e}`);
    for (let n = 0; n < t.length; n++)
      this.prepareInterceptedLink(t[n]), t[n].onclick = (s) => (this.onInterceptedLinkClick(s, t[n]), !1);
  },
  observeDynamicallyAddedLinks() {
    new MutationObserver((t) => {
      const n = m.interceptLinks.ignore.map((s) => `:not(${s})`).join("");
      t.forEach((s) => {
        s.addedNodes.forEach((r) => {
          let a = [];
          if (!(typeof r == "string" || (r == null ? void 0 : r.nodeName) === "#text")) {
            if ((r == null ? void 0 : r.localName) === "a") {
              if (r.className.split(" ").some((i) => m.interceptLinks.ignoredClasses.indexOf(i) >= 0))
                return;
              a.push(r);
            } else
              a = [...a, ...r == null ? void 0 : r.querySelectorAll(`a${n}`)];
            for (let i = 0; i < a.length; i++)
              this.prepareInterceptedLink(a[i]), a[i].onclick = (l) => (this.onInterceptedLinkClick(l, a[i]), !1);
          }
        });
      });
    }).observe(document, {
      childList: !0,
      subtree: !0
    });
  },
  onInterceptedLinkClick(e, t) {
    return window.connect.emit("link-click", {
      url: t.dataset.href,
      target: t.target
    }), !1;
  }
}, I = () => new Proxy(new URLSearchParams(window.location.search), {
  get: (e, t) => e.get(t)
}), E = {
  init() {
    class e extends HTMLElement {
      constructor() {
        super();
        p(this, "settings", {
          interceptLinks: !1,
          src: "",
          frameKey: ""
        });
      }
      connectedCallback() {
        this.setupFrame(), this.innerHTML = `<iframe src="${this.frameURL}" id="connect-frame-${this.frameId}" class="connect-frame" allowfullscreen allowpaymentrequest allow="geolocation; microphone; camera"></iframe>`;
        const s = document.createElement("style");
        s.textContent = `
          .connect-frame {
            border: 0 !important;
            width: 100%;
            overflow: hidden;
            outline: 0;
            height: 0px;
          }
        `, this.appendChild(s), this.setFrameElement(), this.setupMainToFrameListeners(), this.frameElement && this.frameElement.addEventListener("load", this.onLoad);
      }
      disconnectedCallback() {
      }
      get frameId() {
        return this._frameId;
      }
      set frameId(s) {
        this._frameId = s;
      }
      get frameURL() {
        return this._frameURL;
      }
      set frameURL(s) {
        this._frameURL = s;
      }
      set frameElement(s) {
        this._frameElement = s;
      }
      get frameElement() {
        return this._frameElement;
      }
      setupFrame() {
        this.setFrameId(), this.setupProps(), this.prepareFrameURL(), this.setupListeners();
      }
      setupProps() {
        const s = this.getAttribute("intercept-links"), r = this.getAttribute("src"), a = this.getAttribute("url"), i = this.getAttribute("frame-key");
        s !== null && (s !== "" && s !== "true" ? this.settings.interceptlinks = !1 : this.settings.interceptLinks = !0), r !== null && (this.settings.src = r), a !== null && (this.settings.src = a), i !== null && (console.log("FRAME KEY", i), this.settings.frameKey = i), o.frame.frameKey = i;
      }
      setFrameId() {
        this.frameId = String(Math.floor(Math.random() * 1e5));
      }
      setFrameElement() {
        this.frameElement = this.querySelector(`#connect-frame-${this.frameId}`);
      }
      prepareFrameURL() {
        var l;
        const s = {
          connectFrameId: this.frameId
        };
        let r = new URLSearchParams(s).toString();
        r = this.toKebabCase(r);
        const a = (l = this.settings.src) != null && l.includes("?") ? "&" : "?", i = `${this.settings.src}${a}${r}`;
        this.frameURL = i;
      }
      sendMessageToFrame(s, r) {
        this.frameElement && this.frameElement.contentWindow.postMessage({
          event: `connect-${s}`,
          sender: {
            type: "main"
          },
          ...r
        }, "*");
      }
      toKebabCase(s) {
        return s.replace(/[A-Z]/g, "-$&").toLowerCase();
      }
      setupListeners() {
        window.addEventListener("message", (s) => {
          var f, h, u;
          const r = (f = s == null ? void 0 : s.data) == null ? void 0 : f.event, a = (u = (h = s == null ? void 0 : s.data) == null ? void 0 : h.frame) == null ? void 0 : u.frameId, i = s == null ? void 0 : s.data;
          if (a !== this.frameId)
            return;
          r === "connect-frame-height" && this.setFrameHeight(i == null ? void 0 : i.height), r === "connect-setup-request" && this.sendSetupMessageToFrame();
          const l = r.split("connect-")[1];
          c.notifyListeners(l, {
            ...i,
            event: l
          });
        });
      }
      setupMainToFrameListeners() {
        c.on("to-frame", (s) => {
          const r = s.options.frameKey === void 0 ? "frame-id" : "frame-key";
          if (r === "frame-id" && s.options.frameId !== this.frameId || r === "frame-key" && s.options.frameKey !== this.settings.frameKey)
            return !1;
          this.sendMessageToFrame(s.options.event, s.payload);
        });
      }
      setFrameHeight(s) {
        this.querySelector(`#connect-frame-${this.frameId}`).style.height = `${s}px`;
      }
      sendSetupMessageToFrame() {
        c.emit({
          frameId: this.frameId,
          event: "frame-setup"
        }, {
          settings: this.settings,
          design: o.design
        });
      }
      onLoad() {
        c.notifyListeners("frame-load", {
          event: "frame-load",
          frame: {
            ...o.frame,
            frameId: this.frameId
          }
        });
      }
      onMessage() {
      }
    }
    "customElements" in window && customElements.define("connect-frame", e);
  }
}, F = (e) => {
  E.init(), m.set(e), o.url.query = I(), o.design = e.design, o.isInFrame() && (o.frame.frameId = o.url.query["connect-frame-id"], k.init());
}, w = {
  env: o,
  init: F,
  config: m,
  ...c
};
window.connect = w;
export {
  w as default
};
