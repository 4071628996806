import axios from 'axios';

import { Preferences } from '@capacitor/preferences';

let apiURL = 'https://analytics.services.api.lokaldirekt.de';

if (process.env.NODE_ENV === 'development') {
  apiURL = 'http://localhost:3001';
}

function setVisitorExpiration() {
  // set info whether the visitor should be counted
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    const now = new Date();
    const expirationDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);

    await Preferences.set({
      key: 'e',
      value: expirationDate.toUTCString(),
    });

    resolve(true);
  });
}

function getVisitorExpirationDate() {
  // check if the visitor should be counted now
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    const { value } = await Preferences.get({ key: 'e' });

    resolve(new Date(value));
  });
}

function shouldCountVisitor() {
  // check if the visitor should be counted now
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    const expirationDate = await getVisitorExpirationDate();
    const now = new Date();

    if (now > expirationDate) {
      // visitor record expired, set new expiration date
      await setVisitorExpiration();

      resolve(true);
    } else {
      // visitor record is still valid, don't count visitor
      resolve(false);
    }
  });
}

export default function (type, config) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      // check if visitor should be counted
      const countVisitor = await shouldCountVisitor();

      let payload = {
        type,
        platform: config.platform,
        target: config.target,
        unique: countVisitor,
        locale: navigator.language,
      };

      if (config?.placement !== undefined) {
        payload.placement = config.placement;
      }

      if (config?.adId !== undefined) {
        payload.adId = config.adId;
      }

      // send request
      const response = await axios.post(`${apiURL}/events`, payload);

      resolve(response.data);
    } catch (error) {
      resolve();
    }
  });
}
