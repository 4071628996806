/*
  eslint no-shadow: ["error", { "allow": ["state"] }]
*/

const state = {
  departments: [],
};

const getters = {
  departments: (state) => state.departments,
};

const actions = {
  async fetchDepartments({ commit }) {
    // todo: find saved posts in local storage and then set them
    commit('setDepartments', [{
      id: '12312673',
      name: 'Essen & Trinken',
      path: '/essen-und-trinken',
      categoryId: '',
      color: 'stripe-1',
    }, {
      id: '12312793',
      name: 'Bauen & Wohnen',
      path: '/bauen-und-wohnen',
      categoryId: '',
      color: 'stripe-2',
    }]);
  },
};

const mutations = {
  setDepartments: (state, departments) => {
    state.departments = departments;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
