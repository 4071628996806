/*
  eslint no-shadow: ["error", { "allow": ["state"] }]
*/
import { Preferences } from '@capacitor/preferences';

const state = {
  subscribedPushTopics: [],
};

const getters = {
  subscribedPushTopics: (state) => state.subscribedPushTopics,
};

const actions = {
  async addSubscribedPushTopic({ commit }, topic) {
    // get subscribed topics
    const { value } = await Preferences.get({
      key: 'push-subscribed-topics',
    });

    const topics = value !== null ? JSON.parse(value) : [];

    // add a new one
    topics.push(topic);

    const stringifiedTopics = JSON.stringify(topics);

    await Preferences.set({
      key: 'push-subscribed-topics',
      value: stringifiedTopics,
    });

    commit('setSubscribedPushTopics', topics);
  },
  async removeSubscribedPushTopic({ commit }, topic) {
    const { value } = await Preferences.get({
      key: 'push-subscribed-topics',
    });

    let topics = value !== null ? JSON.parse(value) : [];

    topics = topics.filter((item) => item !== topic);

    const stringifiedTopics = JSON.stringify(topics);

    await Preferences.set({
      key: 'push-subscribed-topics',
      value: stringifiedTopics,
    });

    commit('setSubscribedPushTopics', topics);
  },
  async fetchSubscribedPushTopics({ commit }) {
    const { value } = await Preferences.get({ key: 'push-subscribed-topics' });

    const topics = value !== null ? JSON.parse(value) : [];

    commit('setSubscribedPushTopics', topics);
  },
};

const mutations = {
  setSubscribedPushTopics: (state, topics) => {
    state.subscribedPushTopics = topics;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
