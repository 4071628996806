/*
  eslint no-shadow: ["error", { "allow": ["state"] }]
*/

const state = {
  obituaries: [],
};

const getters = {
  obituaries: (state) => state.obituaries,
};

const actions = {
  async fetchObituaries({ commit }, { page }) {
    const postsPage = page !== undefined ? Number(page) + 1 : 1;
    const response = await fetch(`https://redaktion.lokaldirekt.de/wp-json/wp/v2/todesanzeigen?_embed&per_page=20&page=${postsPage}`);

    // if (query) {
    //   response = await fetch(`https://redaktion.lokaldirekt.de/wp-json/wp/v2/posts?_embed&page=${query.page}`);
    // } else {
    //   response = await fetch('https://redaktion.lokaldirekt.de/wp-json/wp/v2/posts?_embed');
    // }

    let posts = await response.json();

    posts = posts.map((post) => {
      let places = [];

      // eslint-disable-next-line no-underscore-dangle
      if (post._embedded) {
        // eslint-disable-next-line no-underscore-dangle
        if (post._embedded['wp:term'] !== undefined) {
          // eslint-disable-next-line no-underscore-dangle
          if (post._embedded['wp:term'].length >= 3) {
            // eslint-disable-next-line no-underscore-dangle
            places = post._embedded['wp:term'][2].map((place) => ({
              categoryId: place.id,
              name: place.name,
              pathName: place.slug,
            }));
          }
        }
      }

      // OBITUARY IMAGE
      let image = null;

      if (post.acf.death_notice) {
        image = post.acf.death_notice;
      }

      return {
        id: post.id,
        image,
        places,
      };
    });

    commit('setObituaries', posts);
  },
};

const mutations = {
  setObituaries: (state, obituaries) => {
    state.obituaries = obituaries;
  },
  addObituaries: (state, obituaries) => {
    [].push.apply(state.obituaries, obituaries);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
