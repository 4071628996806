<template>
  <div id="app">
    <app-preloader v-if="!env.isEmbedded"/>
    <!-- <app-more-menu :show="ui.showMoreMenu"/> -->
    <download-app-hint v-if="!env.isEmbedded"/>
    <!-- <router-view :key="$route.path" id="view" class="view"/> -->
    <ion-app id="router-outlet">
      <ion-router-outlet id="ion-router-outlet-content" :animated="false"/>
    </ion-app>
  </div>
</template>

<script>
/* eslint-disable no-useless-escape */
import { mapGetters, mapActions } from 'vuex';
import { App } from '@capacitor/app';
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { PushNotifications } from '@capacitor/push-notifications';
import { StatusBar, Style } from '@capacitor/status-bar';
import analytics from '@/analytics/index';

// import PullToRefresh from 'pulltorefreshjs';

// import AppMoreMenu from './components/AppMoreMenu.vue';
import AppPreloader from '@/components/layout/AppPreloader.vue';

import DownloadAppHint from '@/components/DownloadAppHint.vue';

export default {
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    // AppMoreMenu,
    AppPreloader,
    DownloadAppHint,
  },
  data() {
    return {
      useTracking: false,
    };
  },
  watch: {
    $route() {
      this.trackPageView();
    },
    allowTracking(value) {
      this.useTracking = value;
    },
    darkmode() {
      this.setupDarkmode();
    },
  },
  methods: {
    ...mapActions(['fetchEnvironmentInfo', 'fetchSavedPosts', 'setEmbeddedStatus', 'fetchTrackingConsent', 'setDarkAppearance', 'fetchAppSetupCompletion']),
    trackPageView() {
      const platform = this.env.isApp ? 'lokaldirekt-app' : 'lokaldirekt-web';

      analytics.event('visit', {
        target: this.$route.fullPath,
        platform,
      });

      // if (this.useTracking) {
      //   // re-add matomo tracking script to track user across pages
      //   const script = document.createElement('script');

      //   script.innerHTML = `
      //   var _paq = window._paq = window._paq || [];
      //   _paq.push(['disableCookies']);_paq.push(['enableLinkTracking']);_paq.push(['alwaysUseSendBeacon']);_paq.push(['setTrackerUrl', "\/\/redaktion.lokaldirekt.de\/wp-content\/plugins\/matomo\/app\/matomo.php"]);_paq.push(['setSiteId', '1']);var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
      //   g.type='text/javascript'; g.async=true; g.src="\/\/redaktion.lokaldirekt.de\/wp-content\/uploads\/matomo\/matomo.js"; s.parentNode.insertBefore(g,s);
      //   _paq.push(['setCustomUrl', 'https://lokaldirekt.de${this.$route.fullPath}']);
      //   _paq.push(['trackPageView']);
      //   `;

      //   document.body.appendChild(script);
      // }
    },
    async setColorScheme(event) {
      const colorScheme = event.matches ? 'dark' : 'light';

      if (colorScheme === 'light') {
        this.setDarkAppearance(false);
        document.body.classList.remove('darkmode');
        if (this.env.isApp) {
          await StatusBar.setStyle({ style: Style.Light });
        }
      } else {
        this.setDarkAppearance(true);
        document.body.classList.add('darkmode');
        if (this.env.isApp) {
          await StatusBar.setStyle({ style: Style.Dark });
        }
      }
    },
    async setupDarkmode() {
      if (this.env.darkmode === 'light') {
        document.body.classList.remove('darkmode');
        window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', this.setColorScheme);
        if (this.env.isApp) {
          await StatusBar.setStyle({ style: Style.Light });
        }
      } else if (this.env.darkmode === 'dark') {
        document.body.classList.add('darkmode');
        window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', this.setColorScheme);
        if (this.env.isApp) {
          await StatusBar.setStyle({ style: Style.Dark });
        }
      } else if (this.env.darkmode === 'system') {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.setColorScheme);
        this.setColorScheme(window.matchMedia('(prefers-color-scheme: dark)'));
      }
    },
    async setupAnalyticsSession() {
      // start analytics session on app open
      analytics.session.start();

      App.addListener('appStateChange', (event) => {
        const platform = this.env.isApp ? 'lokaldirekt-app' : 'lokaldirekt-web';

        if (event.isActive) {
          // app is active
          analytics.session.start();
        } else {
          // app is inactive/backgrounded
          analytics.session.stop({
            target: this.$route.fullPath,
            platform,
          });
        }
      });
    },
  },
  computed: {
    ...mapGetters(['ui', 'env', 'allowTracking', 'darkmode']),
  },
  async created() {
    // App Startup Routine
    await this.fetchEnvironmentInfo();

    this.setupDarkmode();

    if (this.$route.query.embedded) {
      this.setEmbeddedStatus(true);
    }

    this.fetchSavedPosts();

    this.useTracking = this.allowTracking;

    await this.fetchTrackingConsent();

    this.trackPageView();

    // fetch app setup state to show onboarding if needed
    await this.fetchAppSetupCompletion();

    // only show onboarding when user is app user
    if (!this.env.isAppSetupCompleted && this.env.isApp) {
      this.$router.replace({ name: 'Onboarding' });
    }

    // MOBILE APP-SPECIFIC
    if (this.env.isApp) {
      // notifications
      await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
        if (notification.actionId === 'tap') {
          // user tapped on notification
          const { data } = notification.notification;
          // console.log('Push notification action performed', notification.actionId, notification.inputValue, notification);
          if (data.type === 'post') {
            // open post
            this.$router.push({
              name: 'NewsDetail',
              params: {
                pathName: data.pathName,
              },
            });
          }
        }
      });
    }

    // setup analytics session
    this.setupAnalyticsSession();

    // Activate Pull To Refresh
    // eslint-disable-next-line no-unused-vars
    // const ptr = PullToRefresh.init({
    //   mainElement: document.getElementById('view'),
    //   instructionsPullToRefresh: 'Zum Aktualisieren nach unten wischen.',
    //   onRefresh() {
    //     window.location.reload();
    //   },
    // });
  },
};
</script>

<style>
/* oswald-300 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  /* IE9 Compat Modes */
  src: url('./assets/fonts/oswald/oswald-v36-latin-300.eot');
  src: local(''),
    /* IE6-IE8 */
    url('./assets/fonts/oswald/oswald-v36-latin-300.eot?#iefix') format('embedded-opentype'),
    /* Super Modern Browsers */
    url('./assets/fonts/oswald/oswald-v36-latin-300.woff2') format('woff2'),
    /* Modern Browsers */
    url('./assets/fonts/oswald/oswald-v36-latin-300.woff') format('woff'),
    /* Safari, Android, iOS */
    url('./assets/fonts/oswald/oswald-v36-latin-300.ttf') format('truetype'),
    /* Legacy iOS */
    url('./assets/fonts/oswald/oswald-v36-latin-300.svg#Oswald') format('svg');
}
/* oswald-regular - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  /* IE9 Compat Modes */
  src: url('./assets/fonts/oswald/oswald-v36-latin-regular.eot');
  src: local(''),
    /* IE6-IE8 */
    url('./assets/fonts/oswald/oswald-v36-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* Super Modern Browsers */
    url('./assets/fonts/oswald/oswald-v36-latin-regular.woff2') format('woff2'),
    /* Modern Browsers */
    url('./assets/fonts/oswald/oswald-v36-latin-regular.woff') format('woff'),
    /* Safari, Android, iOS */
    url('./assets/fonts/oswald/oswald-v36-latin-regular.ttf') format('truetype'),
    /* Legacy iOS */
    url('./assets/fonts/oswald/oswald-v36-latin-regular.svg#Oswald') format('svg');
}
/* oswald-600 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  /* IE9 Compat Modes */
  src: url('./assets/fonts/oswald/oswald-v36-latin-600.eot');
  src: local(''),
    /* IE6-IE8 */
    url('./assets/fonts/oswald/oswald-v36-latin-600.eot?#iefix') format('embedded-opentype'),
    /* Super Modern Browsers */
    url('./assets/fonts/oswald/oswald-v36-latin-600.woff2') format('woff2'),
    /* Modern Browsers */
    url('./assets/fonts/oswald/oswald-v36-latin-600.woff') format('woff'),
    /* Safari, Android, iOS */
    url('./assets/fonts/oswald/oswald-v36-latin-600.ttf') format('truetype'),
    /* Legacy iOS */
    url('./assets/fonts/oswald/oswald-v36-latin-600.svg#Oswald') format('svg');
}

:root {
  --default-padding: 1.7em;
  --default-padding-large: 1.8em;
  --default-margin: 1em;
  --default-border-radius: 1.3em;
  --default-logo-width: 100px;
  --padding-card: 35px;
  --header-height: calc(4em + env(safe-area-inset-top));
  --bottom-nav-height: 64px;
  --default-gradient-tilt: 18deg;

  --color-primary: #CF2C32;
  --color-secondary: #000105;
  --color-text: #000105;
  --color-background: #FFFFFF;
  --color-background-secondary: #F6F6F6;
  --color-background-tertiary: #e6e6e6;
  --color-background-quaternary: #d1d1d1;
  --color-card: #e0e0e0;
  --color-header: #FFFFFF;
  --color-border: #d6cecf;
  --color-border-card: #d6cecf;
  --color-inactive: #74aa99;
  --color-stripe-1: #701C21;
  --color-stripe-2: #21060B;
  --color-stripe-3: #D02C31;
  --color-stripe-4: #932326;
  --color-stripe-5: #494E4C;

  --color-error: #ef5757;
  --color-warning: #efbd57;
  --color-success: #45be4e;

  --color-primary-100: #d6f5f4;
  --color-primary-200: #aeeae9;
  --color-primary-300: #85e0de;
  --color-primary-400: #5dd5d3;
  --color-primary-500: #34cbc8;
  --color-primary-600: #2aa2a0;
  --color-primary-700: #1f7a78;
  --color-primary-800: #155150;
  --color-primary-900: #0a2928;

  --color-grey: #afafaf;

  --color-error-100: #fbd0d0;
  --color-error-150: #f8b9b9;

  --color-success-400: #6ccb72;

  --color-button-box-shadow: 2px 2px 20px #cccccc;

  --ion-color-primary: #CF2C32;
  --ion-background-color-rgb: rgb(255, 255, 255);

  --swiper-pagination-color: rgb(255, 255, 255);

  --font-size-small: 0.9em;
  --font-size-x-small: 0.8em;

  --breakpoint-mobile: 600px;
  --max-content-width: 850px;

  --ion-background-color: var(--color-background);
  --ion-background-color-rgb: var(--color-background);

  --ion-text-color: var(--color-text);

  --safe-area-top: var(--ion-safe-area-top);
  --safe-area-bottom: var(--ion-safe-area-bottom);
  --safe-area-left: var(--ion-safe-area-left);
  --safe-area-right: var(--ion-safe-area-right);
}

:host {
  --background: var(--color-background) !important;
  --ion-background-color: var(--color-background) !important;
}

body.darkmode, :host .darkmode {
  --color-background: #1d1d1d !important;
  --color-background-secondary: #363636 !important;
  --color-background-tertiary: #4b4b4b !important;
  --color-background-quaternary: rgb(108, 108, 108) !important;

  --color-text: #e9e9e9;
  --color-header: #0f0f0f;
  --color-border: #181818;
  --color-border-card: #464646;
  --color-stripe-2: #692531;
  --color-grey: #3b3b3b;
  --color-button-box-shadow: 2px 2px 20px #272727;
  --ion-text-color: #e9e9e9 !important;
  --ion-background-color: #1d1d1d !important;
  --ion-background-color-rgb: rgb(29, 29, 29) !important;
}

.darkmode .ld-page-content {
  background: var(--color-background);
}

body.darkmode {
  background-color: var(--color-background);
}

* {
  box-sizing: border-box;
}

html, body {
  background-color: var(--color-background) !important;
  color: var(--color-text);
  line-height: 1.69em;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  text-rendering: geometricPrecision;
  overscroll-behavior: none;
  word-break: break-word;
  hyphens: auto;
  -webkit-hyphens: auto;
  font-size: 18px;
  user-select: none;
  -webkit-user-select: none;
}

div {
  color: var(--color-text);
}

body, h1, h2, h3, h4, h5, h6, p, span, i, b, a, strong, button, input, textarea {
  font-family: 'Oswald';
  color: var(--color-text);
  margin: 0px;
}

.ld-card {
  border-radius: 1.2em;
  overflow: hidden;
  margin-bottom: 1em;
  margin-top: 1em;
  padding: 1.6em;
  background: var(--color-background-secondary);
}

@media (min-width: 700px) {
  .ld-card {
    padding: 2.2em;
  }
}

p {
  font-size: 1em;
  font-weight: 300;
  line-height: 1.69em;
}

h1 {
  font-size: 1.8em;
  font-weight: 600;
  margin-bottom: 0.5em;
  line-height: 1.2em;
}

h2 {
  font-size: 1.6em;
  font-weight: 600;
  margin-bottom: 0.8em;
  line-height: 1.2em;
}

h3 {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 0.8em;
}

p {
  margin-bottom: 1em;
}

p+h2 {
  margin-top: 2em;
}

@media (min-width: 800px) {
  h1 {
    font-size: 2.5em;
  }

  h2 {
    font-size: 2em;
  }
}

.cover-slider .swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
  background: var(--color-grey) !important;
}

.swiper-cover-slider.swiper {
  overflow: visible !important;
}

.darkmode .cover-slider .swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
  background: #ffffff !important;
}

.cover-slider .swiper-pagination-bullet-active {
  background: var(--color-primary) !important;
}

figure {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
}

video, iframe {
  width: 100%;
  height: auto;
}

a {
  color: var(--color-primary);
  text-decoration: none;
  transition: 0.2s;
  cursor: pointer;
}

mark {
  background: transparent;
  color: var(--color-primary);
}

input, textarea {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  box-sizing: border-box;

  font-size: 1em;
  font-weight: 300 !important;
}

input:focus, textarea:focus, button:focus, a:focus {
  outline: none;
}

.ad-label {
  margin-bottom: 0.5em;
  padding-left: 1em;
  text-align: left;
}

.article-ad {
  text-align: center;
  max-width: 400px;
  margin: auto;
  margin-top: 2em;
  margin-bottom: 2em;
}

.ad-img {
  border-radius: 1em !important;
  text-align: center;
}

.swiper-button-prev, .swiper-button-next {
  --swiper-theme-color: #ffffff;
}

.font-weight-normal * {
  font-weight: normal !important;
}

.view-content {
  min-height: 100vh;
}

.footnote {
  font-size: var(--font-size-small);
  font-weight: 300;
}
/* .view {
  height: 100%;
  min-height: calc(100vh - var(--header-height));
} */

.inner-content {
  width: 100%;
  max-width: var(--max-content-width);
  margin: auto;
}

.inner-content.small {
  width: 100%;
  max-width: 550px;
  margin: auto;
}

.inner-content.wide {
  max-width: 1100px;
}

.content-padding-lr {
  padding-left: var(--default-padding);
  padding-right: var(--default-padding);
}

.content-padding-tb {
  /* padding-top: var(--default-padding); */
  padding-top: 2em;
  padding-bottom: var(--default-padding);
}

.content-padding-t {
  /* padding-top: var(--default-padding); */
  padding-top: 2em;
}

.content-padding-b {
  /* padding-top: var(--default-padding); */
  padding-top: 2em;
}

.content-padding-t-large {
  padding-top: 4em;
}

.content-padding-b-large {
  padding-top: 4em;
}

.content-padding-tb-large {
  padding-top: 4em;
  padding-bottom: 4em;
}

@media (max-width: 650px) {
  .content-padding-t-large {
    padding-top: 2em;
  }

  .content-padding-b-large {
    padding-top: 2em;
  }

  .content-padding-tb-large {
    padding-top: 2em;
    padding-bottom: 2em;
  }
}

.safe-area-top {
  margin-top: var(--header-height);
}

.align-center {
  text-align: center;
}

.logo {
  width: var(--default-logo-width);
}

.text-input .label, .textarea-input .label {
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 18px;
  text-align: left;
}

.text-input input, .textarea-input textarea {
  background-color: var(--color-card-1);
  padding: 0.8em;
  border-radius: var(--default-border-radius);
  margin-bottom: var(--default-margin);

  cursor: text;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.text-input, .textarea-input {
  margin-bottom: var(--default-padding);
}

.checkbox {
  margin-bottom: var(--default-margin);
}

.icon-margin-left {
  margin-left: 13px;
}

.icon-margin-right {
  margin-right: 13px;
}

.full-width {
  margin-left: calc(var(--default-padding) * -1);
  margin-right: calc(var(--default-padding) * -1);
}

.swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
  background-color: #ffffff !important;
  opacity: 0.4;
}

.ld-page-content {
  margin-bottom: -100vh;
}

.refresher {
  margin-top: 0.6em;
}

/* COVER SLIDER */
.cover-slider .swiper-pagination.swiper-pagination-bullets {
  top: -2.22em !important;
  bottom: initial !important;
  z-index: 100;
  width: 80vw;
  left: 10vw;
}

.cover-slider .swiper-container {
  overflow: initial !important;
}

/* EMBEDDED VIDEO 16:9 */
.wp-embed-aspect-16-9 .wp-block-embed__wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.wp-embed-aspect-16-9 .wp-block-embed__wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* ARTICLE AD */
.article-ad iframe {
  position: static;
  border: 0;
  width: 100%;
  overflow: hidden;
  outline: 0;
  height: 350px;
}

/* ARTICLE IMAGE GALLERY */
.wp-block-gallery {
  width: 100vw;
  padding: 0;
  margin: 0;
  background: var(--color-background-secondary);
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-top: 2.5em;
  margin-bottom: 2.5em;
  padding-top: 2.2em;
  padding-bottom: 2.2em;
  padding-left: var(--default-padding);
  padding-right: var(--default-padding);
}

.wp-block-gallery .blocks-gallery-grid, .wp-block-gallery .wp-block-image  {
  width: 100%;
  max-width: calc(650px - var(--default-padding));
  margin: auto;
  position: relative;
}

.wp-block-gallery img {
  width: 100%;
  object-fit: contain;
  border-radius: 1em;
  margin-top: 0.5em;
  cursor: pointer;
}

.wp-block-gallery .blocks-gallery-item:after {
  /* content: url('/assets/img/svg-icons/gallery.svg'); */
  background: linear-gradient(to bottom, #20202069, transparent);
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 2em);
  height: auto;
  z-index: 100000;
  border-radius: 1em;
  border-bottom-left-radius: 0em;
  border-bottom-right-radius: 0em;
  margin-top: 0.5em;
  margin-bottom: 0.4em;
  pointer-events: none;
  padding: 1em;
  color: #ffffff;
  text-align: right;
}

.wp-block-gallery.has-nested-images .wp-block-image:after {
  /* content: url('/assets/img/svg-icons/gallery.svg'); */
  background: linear-gradient(to bottom, #20202069, transparent);
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 2em);
  height: auto;
  border-radius: 1em;
  border-bottom-left-radius: 0em;
  border-bottom-right-radius: 0em;
  margin-top: 0.5em;
  margin-bottom: 0.4em;
  padding: 1em;
  color: #ffffff;
  text-align: right;
  pointer-events: none;
  cursor: pointer;
}

.wp-block-image {
  margin-top: 2em;
  margin-bottom: 2em;
  pointer-events: auto !important;
}

/* .wp-block-gallery img:before {
  content: "Bildergalerie";
} */

/* .wp-block-gallery li:before, .wp-block-gallery .wp-block-image {
  content: "Bildergalerie";
  width: 100%;
} */

.wp-block-gallery li:not(:first-child), .wp-block-gallery .wp-block-image:not(:first-child) {
  /* HIDE ALL IMAGES EXCEPT THE FIRST ONE */
  display: none !important;
}

.wp-block-gallery ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  white-space: nowrap;
}

/* .wp-block-gallery ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
}

.wp-block-gallery li {
  width: 100%;
  max-width: 100%;
  display: inline-block;
  scroll-snap-align: center;
} */

.wp-block-gallery .blocks-gallery-item__caption {
  white-space: normal;
  min-height: 4em;
}

/* .wp-block-gallery li:nth-child(even) img {
  transform: translateY(-1.65em);
} */

.markable {
  user-select: text;
  -webkit-user-select: text;
}

.overview p {
 font-weight: normal !important;
}

::selection {
  background: var(--color-stripe-4);
  color: #ffffff;
}

::-moz-selection {
  background: var(--color-primary);
  color: #ffffff;
}

.ad-link.no-link {
  cursor: auto;
  pointer-events: none;
}

/* IONIC */
/* .page {
  height: 100vh;
} */

body.backdrop-no-scroll {
  overflow: hidden;
}

html.ios ion-modal.modal-card .ion-page > ion-header > ion-toolbar:first-of-type {
  padding-top: 0px;
}

html.ios ion-modal .ion-page {
  border-radius: inherit;
}

ion-modal {
  --ion-background-color: var(--color-background);
}

.ion-color-primary {
  --ion-color-base: var(--ion-color-primary, #CF2C32) !important;
  --ion-color-base-rgb: var(--ion-color-primary-rgb, 56, 128, 255) !important;
  --ion-color-contrast: var(--ion-color-primary-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-primary-shade, #3171e0) !important;
  --ion-color-tint: var(--ion-color-primary-tint, #4c8dff) !important;
}

.ion-color-secondary {
  --ion-color-base: var(--ion-color-secondary, #3dc2ff) !important;
  --ion-color-base-rgb: var(--ion-color-secondary-rgb, 61, 194, 255) !important;
  --ion-color-contrast: var(--ion-color-secondary-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-secondary-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-secondary-shade, #36abe0) !important;
  --ion-color-tint: var(--ion-color-secondary-tint, #50c8ff) !important;
}

.ion-color-tertiary {
  --ion-color-base: var(--ion-color-tertiary, #5260ff) !important;
  --ion-color-base-rgb: var(--ion-color-tertiary-rgb, 82, 96, 255) !important;
  --ion-color-contrast: var(--ion-color-tertiary-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-tertiary-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-tertiary-shade, #4854e0) !important;
  --ion-color-tint: var(--ion-color-tertiary-tint, #6370ff) !important;
}

.ion-color-success {
  --ion-color-base: var(--ion-color-success, #2dd36f) !important;
  --ion-color-base-rgb: var(--ion-color-success-rgb, 45, 211, 111) !important;
  --ion-color-contrast: var(--ion-color-success-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-success-shade, #28ba62) !important;
  --ion-color-tint: var(--ion-color-success-tint, #42d77d) !important;
}

.ion-color-warning {
  --ion-color-base: var(--ion-color-warning, #ffc409) !important;
  --ion-color-base-rgb: var(--ion-color-warning-rgb, 255, 196, 9) !important;
  --ion-color-contrast: var(--ion-color-warning-contrast, #000) !important;
  --ion-color-contrast-rgb: var(--ion-color-warning-contrast-rgb, 0, 0, 0) !important;
  --ion-color-shade: var(--ion-color-warning-shade, #e0ac08) !important;
  --ion-color-tint: var(--ion-color-warning-tint, #ffca22) !important;
}

.ion-color-danger {
  --ion-color-base: var(--ion-color-danger, #eb445a) !important;
  --ion-color-base-rgb: var(--ion-color-danger-rgb, 235, 68, 90) !important;
  --ion-color-contrast: var(--ion-color-danger-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-danger-shade, #cf3c4f) !important;
  --ion-color-tint: var(--ion-color-danger-tint, #ed576b) !important;
}

.ion-color-light {
  --ion-color-base: var(--ion-color-light, #f4f5f8) !important;
  --ion-color-base-rgb: var(--ion-color-light-rgb, 244, 245, 248) !important;
  --ion-color-contrast: var(--ion-color-light-contrast, #000) !important;
  --ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb, 0, 0, 0) !important;
  --ion-color-shade: var(--ion-color-light-shade, #d7d8da) !important;
  --ion-color-tint: var(--ion-color-light-tint, #f5f6f9) !important;
}

.ion-color-medium {
  --ion-color-base: var(--ion-color-medium, #92949c) !important;
  --ion-color-base-rgb: var(--ion-color-medium-rgb, 146, 148, 156) !important;
  --ion-color-contrast: var(--ion-color-medium-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-medium-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-medium-shade, #808289) !important;
  --ion-color-tint: var(--ion-color-medium-tint, #9d9fa6) !important;
}

.ion-color-dark {
  --ion-color-base: var(--ion-color-dark, #222428) !important;
  --ion-color-base-rgb: var(--ion-color-dark-rgb, 34, 36, 40) !important;
  --ion-color-contrast: var(--ion-color-dark-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-dark-shade, #1e2023) !important;
  --ion-color-tint: var(--ion-color-dark-tint, #383a3e) !important;
}

.ion-page {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  contain: layout size style;
  overflow: hidden;
  z-index: 0;
}

.split-pane-visible > .ion-page.split-pane-main {
  position: relative;
}

ion-route,
ion-route-redirect,
ion-router,
ion-select-option,
ion-nav-controller,
ion-menu-controller,
ion-action-sheet-controller,
ion-alert-controller,
ion-loading-controller,
ion-modal-controller,
ion-picker-controller,
ion-popover-controller,
ion-toast-controller,
.ion-page-hidden,
[hidden] {
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
}

.ion-page-invisible {
  opacity: 0;
}

.can-go-back > ion-header ion-back-button {
  display: block;
}

html.plt-ios.plt-hybrid, html.plt-ios.plt-pwa {
  --ion-statusbar-padding: 20px;
}

/* @supports (padding-top: 20px) {
  html {
    --ion-safe-area-top: var(--ion-statusbar-padding);
  }
}
@supports (padding-top: constant(safe-area-inset-top)) {
  html {
    --ion-safe-area-top: constant(safe-area-inset-top);
    --ion-safe-area-bottom: constant(safe-area-inset-bottom);
    --ion-safe-area-left: constant(safe-area-inset-left);
    --ion-safe-area-right: constant(safe-area-inset-right);
  }
}
@supports (padding-top: env(safe-area-inset-top)) {
  html {
    --ion-safe-area-top: env(safe-area-inset-top);
    --ion-safe-area-bottom: env(safe-area-inset-bottom);
    --ion-safe-area-left: env(safe-area-inset-left);
    --ion-safe-area-right: env(safe-area-inset-right);
  }
} */

ion-content {
  min-height: 100vh !important;
  --offset-top: 0px !important;
  background: var(--color-background) !important;
}

ion-card.ion-color .ion-inherit-color,
ion-card-header.ion-color .ion-inherit-color {
  color: inherit;
}

.menu-content {
  transform: translate3d(0,  0,  0);
}

.menu-content-open {
  cursor: pointer;
  touch-action: manipulation;
  pointer-events: none;
}

.ios .menu-content-reveal {
  box-shadow: -8px 0 42px rgba(0, 0, 0, 0.08);
}

[dir=rtl].ios .menu-content-reveal {
  box-shadow: 8px 0 42px rgba(0, 0, 0, 0.08);
}

ion-button.nostyle {
  padding: 0px !important;
  width: auto !important;
  background: transparent !important;
  color: inherit;

  --background: transparent;
  --background-hover: transparent;
  --background-focused: transparent;
  --background-activated: transparent;
  --padding-bottom: 0em;
  --padding-top: 0em;
  --padding-end: 0em;
  --padding-start: 0em;
  --border-radius: 0em;
}

ion-button.nostyle:deep(button) {
  background: transparent !important;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
}

ion-spinner.spinner-ios line {
  stroke: var(--color-text) !important;
}

@media print {
  #printable {
    display: block !important;
  }

  #app {
    display: none;
  }
}

.hide-navigation .swiper-button-disabled {
  display: none !important;
}

ion-refresher ion-refresher-content {
  --color: var(--color-text) !important;
}

img.lazy-loading {
  opacity: 0;
  transition: 0.25s;
}

img.lazy-loading.loaded {
  opacity: 1;
}

.loaded img.lazy-loading {
  opacity: 1;
}

.footnote-spacing {
  margin-top: 2em;
}
</style>
