import axios from 'axios';

let apiURL = 'https://analytics.services.api.lokaldirekt.de';

if (process.env.NODE_ENV === 'development') {
  apiURL = 'http://localhost:3001';
}

export default {
  startDate: new Date(),
  start() {
    this.startDate = new Date();
  },
  stop(config) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        // send request
        const response = await axios.post(`${apiURL}/sessions`, {
          platform: config.platform,
          target: config.target,
          locale: navigator.language,
          startDate: new Date(this.startDate).toISOString(),
          endDate: new Date().toISOString(),
        });

        resolve(response.data);
      } catch (error) {
        resolve();
      }
    });
  },
};
