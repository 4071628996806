/* eslint-disable prefer-destructuring */
/*
  eslint no-shadow: ["error", { "allow": ["state"] }]
*/

const state = {
  currentlyShownAds: [],
  ads: [],
  selectedAd: {

  },
};

function prepareAdURL(config) {
  let url = '';

  if (!config?.type && config?.url === '') {
    return '';
  }

  if (config?.type === 'email') {
    // type is email
    url = `mailto:${config.email}`;

    // add params
    const emailParams = {
    };

    if (config?.subject !== undefined) {
      emailParams.subject = config.subject;
    }

    if (config?.body !== undefined) {
      emailParams.body = config.body;
    }

    // create query string
    let queryString = new URLSearchParams(emailParams).toString();

    queryString = queryString?.length > 0 ? `?${queryString}` : '';

    // create url
    url = url + queryString;

    console.log('created url', url);

  } else {
    // type is url
    url = config?.url;
  }

  return url;
};

const getters = {
  ads: (state) => state.ads,
  currentlyShownAds: (state) => state.currentlyShownAds,
  selectedAd: (state) => state.selectedAd,
  getAdByPlacementId: (state) => (placementId) => {
    console.log();
    const adsByPlacement = state.ads.filter((ad) => {
      console.log();
      return ad.placement.some((id) => id === placementId);
    });

    let matchingAd = null;

    // if more than one ad is available, take a random one
    if (adsByPlacement.length > 1) {
      matchingAd = adsByPlacement[Math.floor(Math.random() * adsByPlacement.length)];
    } else {
      matchingAd = adsByPlacement[0];
    }

    return matchingAd;
  },
};

const actions = {
  async fetchCurrentlyShownAds({ commit }) {
    const response = await fetch('https://redaktion.lokaldirekt.de/wp-json/wp/v2/ad?_embed&per_page=100');

    const ads = await response.json();

    if (ads.length === 0) {
      return;
    }

    const allAds = [];

    console.log('AD RESPONSE', ads);

    for (let i = 0; i < ads.length; i += 1) {
      const ad = ads[i];

      if (!ad._embedded) {
        continue;
      }

      let image = '';

      // eslint-disable-next-line no-underscore-dangle
      if (ad._embedded['wp:featuredmedia']) {
        // eslint-disable-next-line no-underscore-dangle
        image = ad._embedded['wp:featuredmedia'][0].source_url;
      }

      let placement = [];

      // eslint-disable-next-line no-underscore-dangle
      if (ad._embedded['wp:term']) {
        // eslint-disable-next-line no-underscore-dangle
        if (ad._embedded['wp:term'].length >= 3) {
          // eslint-disable-next-line no-underscore-dangle
          placement = ad._embedded['wp:term'][2].map((placementItem) => (Number(placementItem.id)));
        }
      }

      allAds.push({
        id: ad.id,
        placement,
        image,
        url: prepareAdURL({
          url: ad?.acf?.url,
          type: ad?.acf['link_type'] ?? undefined,
          email: ad?.acf?.email ?? undefined,
          subject: ad?.acf['email_subject'] ?? undefined,
          body: ad?.acf['email_content'] ?? undefined,
        })
      });
    }

    commit('addCurrentlyShownAds', allAds);
  },
  async fetchAd({ commit }, { placementId }) {
    const response = await fetch(`https://redaktion.lokaldirekt.de/wp-json/wp/v2/ad?_embed&placement=${placementId}`);

    const ads = await response.json();

    if (ads.length === 0) {
      return;
    }

    // const ad = ads[0];

    const adsByPlacement = ads.map((ad) => {
      let image = '';

      // eslint-disable-next-line no-underscore-dangle
      if (ad._embedded['wp:featuredmedia']) {
        // eslint-disable-next-line no-underscore-dangle
        image = ad._embedded['wp:featuredmedia'][0].source_url;
      }

      let placement = [];

      // eslint-disable-next-line no-underscore-dangle
      if (ad._embedded['wp:term']) {
        // eslint-disable-next-line no-underscore-dangle
        if (ad._embedded['wp:term'].length >= 3) {
          // eslint-disable-next-line no-underscore-dangle
          placement = ad._embedded['wp:term'][2].map((placementItem) => (Number(placementItem.id)));
        }
      }

      return {
        id: ad.id,
        placement,
        image,
        url: ad.acf.url,
      };
    });

    commit('addAds', adsByPlacement);
  },
  async fetchRandomAd({ commit }, {
    placementId,
    exclude,
    places,
    categories,
  }) {
    const url = 'https://redaktion.lokaldirekt.de/wp-json/wp/v2/ad?_embed';

    // BUILD PREFLIGHT QUERY
    let preflightQuery = {
      per_page: '1',
    };

    if (placementId) {
      preflightQuery.placement = placementId;
    }

    if (exclude) {
      preflightQuery.exclude = exclude.toString();
    }

    if (places) {
      preflightQuery.places = places.toString();
    }

    if (categories) {
      preflightQuery.categories = categories.toString();
    }

    preflightQuery = new URLSearchParams(preflightQuery);

    preflightQuery = preflightQuery.toString();

    // MAKE PREFLIGHT REQUEST
    const preflightResponse = await fetch(`${url}&${preflightQuery}`);
    const preflightAds = await preflightResponse.json();

    let maxAvailableAds = Number(preflightResponse.headers.get('X-WP-Total'));

    if (exclude && maxAvailableAds > exclude.length) {
      // reduce the available ads when some ads are excluded
      maxAvailableAds -= exclude.length;
    }

    // BUILD COMPLETE QUERY
    const randomPage = Math.floor(Math.random() * (maxAvailableAds - 1 + 1) + 1);
    const completeQuery = `${preflightQuery}&page=${randomPage}`;

    // MAKE REQUEST
    const response = await fetch(`${url}&${completeQuery}`);

    let ads = await response.json();

    if (!ads.length) {
      if (preflightAds.length) {
        ads = preflightAds;
      } else {
        ads = [{
          id: null,
          image: '',
          acf: {
            url: '',
          },
          _embedded: {
            'wp:featuredmedia': [
              {
                source_url: '',
              },
            ],
          },
        }];
      }
    }

    const ad = {
      id: ads[0].id,
      image: '',
      url: ads[0].acf.url,
    };

    // eslint-disable-next-line no-underscore-dangle
    if (ads[0]._embedded['wp:featuredmedia']) {
      // eslint-disable-next-line no-underscore-dangle
      ad.image = ads[0]._embedded['wp:featuredmedia'][0].source_url;
    }

    commit('setSelectedAd', ad);
  },
};

const mutations = {
  addAd: (state, ad) => {
    state.ads.push(ad);
  },
  addAds: (state, ads) => {
    state.ads.push(...ads);
  },
  addCurrentlyShownAds: (state, ads) => {
    state.currentlyShownAds = ads;
  },
  setSelectedAd: (state, ad) => {
    state.selectedAd = ad;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
