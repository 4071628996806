/*
  eslint no-shadow: ["error", { "allow": ["state"] }]
*/

const state = {
  guides: [],
};

const getters = {
  guides: (state) => state.guides,
};

const actions = {
  async fetchGuides({ commit }) {
    // todo: find saved posts in local storage and then set them
    commit('setGuides', [{
      id: '1',
      name: 'Test',
      path: '/test',
    }]);
  },
};

const mutations = {
  setGuides: (state, guides) => {
    state.guides = guides;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
