export default {
  de: {
    'date-short': {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    },
    'date-shorter': {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    },
    'time-short': {
      hour: '2-digit',
      minute: '2-digit',
    },
    'date-time-noyear': {
      weekday: 'short',
      day: '2-digit',
      month: 'long',
      hour: '2-digit',
      minute: '2-digit',
    },
    'date-time': {
      weekday: 'short',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    },
    'weekday-time': {
      weekday: 'short',
      hour: '2-digit',
      minute: '2-digit',
    },
    time: {
      hour: '2-digit',
      minute: '2-digit',
    },
    'date-no-year': {
      day: '2-digit',
      month: 'long',
    },
    'date-year': {
      year: 'numeric',
    },
  },
};
