/*
  eslint no-shadow: ["error", { "allow": ["state"] }]
*/

import { Preferences } from '@capacitor/preferences';

const state = {
  privacy: {
    allowThirdPartyConnections: false,
  },
};

const getters = {
  congestionPrivacy: (state) => state.privacy,
};

const actions = {
  async fetchCongestionPrivacyConsent({ commit }) {
    let { value } = await Preferences.get({
      key: 'congestionallowthirdpartyconnections',
    });

    // eslint-disable-next-line no-unneeded-ternary
    value = value === 'yes' ? true : false; // make null value a Boolean value

    commit('setPrivacyConsent', value);
  },
  async setCongestionPrivacyConsent({ commit }, value) {
    let consent = 'no';

    if (value === 'true' || value === true) {
      consent = 'yes';
    } else {
      consent = null;
    }

    await Preferences.set({
      key: 'congestionallowthirdpartyconnections',
      value: consent,
    });

    commit('setPrivacyConsent', consent);
  },
};

const mutations = {
  setPrivacyConsent: (state, consent) => {
    state.privacy.allowThirdPartyConnections = consent;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
