/*
  eslint no-shadow: ["error", { "allow": ["state"] }]
*/
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';

const state = {
  ui: {
    showMoreMenu: false,
  },
  env: {
    isApp: false,
    isDesktop: false,
    isMobile: true,
    showDownloadHint: false,
    isEmbedded: false,
    allowTracking: true,
    darkmode: 'system',
    isDarkmode: false,
    isAppSetupCompleted: false,
    views: {
      jobs: {
        listHeight: 0,
      },
    },
    defaultPlace: {
      name: 'Halver',
      categoryId: 2,
    },
  },
  allowTracking: false,
};

const getters = {
  config: (state) => state.config,
  ui: (state) => state.ui,
  env: (state) => state.env,
  allowTracking: (state) => state.allowTracking,
  darkmode: (state) => state.env.darkmode,
};

const actions = {
  async setMoreMenuState({ commit }, showState) {
    commit('setMoreMenuState', showState);
  },
  async setEmbeddedStatus({ commit }, showState) {
    commit('setEmbeddedStatus', showState);
  },
  async setJobListHeight({ commit }, height) {
    commit('setJobListHeight', height);
  },
  async setDarkmodeType({ commit }, type) {
    await Preferences.set({
      key: 'darkmode',
      value: type,
    });

    commit('setDarkmodeType', type);
  },
  async setAppSetupCompletion({ commit }, completed) {
    await Preferences.set({
      key: 'app-setup-completed',
      value: String(completed),
    });

    console.log('COMPLETION', completed);

    commit('setAppSetupCompletion', completed);
  },
  async fetchAppSetupCompletion({ commit }) {
    const { value } = await Preferences.get({ key: 'app-setup-completed' });

    // eslint-disable-next-line no-unneeded-ternary
    const completed = value === 'true' ? true : false;

    commit('setAppSetupCompletion', completed);
  },
  async setDarkAppearance({ commit }, active) {
    commit('setDarkAppearance', active);
  },
  async setTrackingConsent({ commit }, consent) {
    await Preferences.set({
      key: 'allowtracking',
      value: JSON.stringify(consent),
    });

    commit('setTrackingConsent', consent);
  },
  async fetchTrackingConsent({ commit }) {
    const { value } = await Preferences.get({ key: 'allowtracking' });

    let allowTracking = value !== null ? JSON.parse(value) : true;

    if (Capacitor.isNativePlatform()) {
      // disable tracking on native
      if (value === null) {
        allowTracking = false;
      }
    }

    commit('setTrackingConsent', allowTracking);
  },
  async setDownloadHintVisibility({ commit }, showDownloadHint) {
    localStorage.setItem('show-download-hint', false);
    commit('setDownloadHintVisibility', showDownloadHint);
  },
  async fetchEnvironmentInfo({ commit }) {
    const env = {
      isApp: false,
      isDesktop: false,
      isMobile: true,
      showDownloadHint: false,
      isEmbedded: false,
      allowTracking: true,
      darkmode: 'system',
      isDarkmode: false,
      views: {
        jobs: {
          listHeight: 0,
        },
      },
      defaultPlace: {
        name: 'Halver',
        categoryId: 2,
      },
    };

    if (Capacitor.isNativePlatform()) {
      env.isApp = true;
    }

    // if app, disallow tracking by default
    if (env.isApp) {
      env.allowTracking = false;
    }

    if (window.matchMedia('(min-width: 1000px)').matches) {
      env.isDesktop = true;
    }

    if (window.matchMedia('(max-width: 550px)').matches) {
      env.isMobile = true;
    } else {
      env.isMobile = false;
    }

    if (!env.isApp && !localStorage.getItem('show-download-hint')) {
      env.showDownloadHint = true;
    }

    // darkmode
    const darkmode = await Preferences.get({ key: 'darkmode' });

    if (darkmode.value === 'light') {
      env.isDarkmode = false;
      env.darkmode = 'light';
    } else if (darkmode.value === 'dark') {
      env.isDarkmode = true;
      env.darkmode = 'dark';
    } else {
      env.isDarkmode = false;
      env.darkmode = 'system';
    }

    // tracking consent
    const { value } = await Preferences.get({ key: 'allowtracking' });

    const allowTracking = value !== null ? JSON.parse(value) : true;

    if (!allowTracking) {
      env.allowTracking = false;
    }

    commit('setEnvironmentInfo', env);
  },
};

const mutations = {
  setMoreMenuState: (state, showState) => {
    state.ui.showMoreMenu = showState;
  },
  setEmbeddedStatus: (state, showState) => {
    state.env.isEmbedded = showState;
  },
  setEnvironmentInfo: (state, env) => {
    state.env = env;
  },
  setDarkmodeType: (state, type) => {
    state.env.darkmode = type;
  },
  setDarkAppearance: (state, active) => {
    state.env.isDarkmode = active;
  },
  setTrackingConsent: (state, consent) => {
    state.allowTracking = consent;
  },
  setJobListHeight: (state, height) => {
    state.env.views.jobs.listHeight = height;
  },
  setDownloadHintVisibility: (state, showDownloadHint) => {
    state.env.showDownloadHint = showDownloadHint;
  },
  setAppSetupCompletion: (state, completed) => {
    state.env.isAppSetupCompleted = completed;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
